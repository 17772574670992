<template lang="">
    <div class="pricing-tier" :class="tierClasses">
        <font-awesome-icon class="icon" :icon="icon" />
        <div class="tier-details" >
            <div class="title">{{tier.name}}</div>
            <div class="description" v-if="availabilityText">{{ availabilityText }}</div>
            <div v-if="status == 'SOLD_OUT' && tier.name == 'Fastpass' && ! registration">O Fastpass esgotou, mas teremos lugares sem reserva para participantes sem inscrição, por ordem de chegada!</div>
            <small v-if="! authenticatedUser && status == 'AVAILABLE'">Para fazer sua inscrição, primeiro você deve entrar em sua conta ieadcdigital.com.br</small>
            <q-btn @click="navigateTo('/auth/login')" color="green" v-if="! authenticatedUser && status == 'AVAILABLE'" outline class="btn-inscrever" label="Login &raquo;" />
            <q-btn @click="navigateTo('/auth/login')" color="green" v-if="! authenticatedUser && status == 'AVAILABLE'" class="btn-inscrever" label="Nova conta &raquo;" />
            <q-btn @click="goToCheckout()" color="green" v-if="authenticatedUser && status == 'AVAILABLE' && ! hideCta && ! registration" outline class="btn-inscrever" label="Inscrever agora &raquo;" />
            <q-btn @click="goToRegistration()" color="green" v-if="authenticatedUser && registration != null && registration?.pricing_tiers.id == tier?.id" outline class="btn-inscrever" label="Ver inscrição &raquo;" />
        </div>
        <div class="price">
            {{ price }}
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapActions, mapGetters } from 'vuex';
    
    export default {
        name: "PriceTier",
        props: {
            tier: Object,
            hideCta: Boolean,
            ignoreExpiration: Boolean,
            registration: Object,
        },
        methods: {
            ...mapActions(['hideOverlay']),
            goToCheckout() {
                this.$router.push(`/checkout/${this.tier.event_id}/${this.tier.id}`);
                this.hideOverlay();
            },
            goToRegistration() {
                this.$router.push(`/my-registrations/${this.registration.id}`);
            },
            navigateTo(to) {
                this.hideOverlay();
                setTimeout(()=>this.$router.push(to), 150)
            },
        },
        computed: {
            ...mapGetters('auth', ['authenticatedUser']),
            status() {
                if (this.ignoreExpiration)
                    return "AVAILABLE";

                if (moment(this.tier.starts_at) > moment()) {
                    return "WAITING";
                }
                
                if (this.tier.available != null && this.tier.available < 1) {
                    return 'SOLD_OUT';
                }

                if (moment(this.tier.ends_at) < moment()) {
                    return "FINISHED";
                }

                return "AVAILABLE";
            },
            icon() {
                switch(this.status) {
                    case 'WAITING':
                        return ['fa', 'clock'];
                    case 'SOLD_OUT':
                        return ['fa', 'close'];
                    case 'FINISHED':
                        return ['fa', 'close'];
                    case 'AVAILABLE':
                        return ['fa', 'fire'];
                }

                return [];
            },
            tierClasses() {
                return {
                    'available': this.status == 'AVAILABLE' || (this.status == 'SOLD_OUT' && this.tier.name == 'Fastpass'),
                    'unavailable': this.status == 'FINISHED' || (this.status == 'SOLD_OUT' && this.tier.name != 'Fastpass'),
                }
            },
            price() {
                if (this.tier.price == 0) {
                    return 'GRÁTIS';
                }
                let Real = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                });
                return Real.format(this.tier.price / 100);
            },
            availabilityText() {
                if (this.status == 'WAITING') {
                    return "Disponível em " + (new Date(this.tier.starts_at)).toLocaleDateString('pt-BR', {day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit'});
                } else if (this.status == 'FINISHED') {
                    return "Encerrado em " + (new Date(this.tier.ends_at)).toLocaleDateString('pt-BR', {day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit'});
                } else if (this.status == 'SOLD_OUT') {
                    return "Esgotado";
                } else if (this.status && this.tier.ends_at) {
                    return "Encerra em " + (new Date(this.tier.ends_at)).toLocaleDateString('pt-BR', {day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit'});
                } else if (this.status && this.tier.total != null) {
                    return "Quantidade limitada! Inscreva-se agora!"
                }

                return false;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .pricing-tier {
        width: 100%;
        padding: 16px;
        border-radius: 8px;
        background-color: #eee;
        color: #333;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        
        .tier-details {
            flex-grow: 1;
            text-align: left;
            .title {
                font-weight: 500;
                font-size: 18px;
            }
    
            .description {
                font-weight: 500;
                font-size: 14px;
            }

            .btn-inscrever {
                width: 100%;
                margin-top: 8px;
            }
        }
    }

    .icon {
        color: #aaa;
        font-size: 24px;
    }

    .available > .icon {
        color: #9cc3a8;
        font-size: 24px;
    }
    
    .available {
        background-color: rgb(229, 255, 239);
    }

    .unavailable {
        opacity: 0.4;
        text-decoration: line-through;
    }

    .price {
        font-weight: 500;
        font-size: 18px;
    }
</style>