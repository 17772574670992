export function buildMpPaymentConfig(amount, method, email, onReady, onSubmit, onError) {
    let methods = {};

    switch (method) {
        case 'mp_pix':
            methods = { bankTransfer: 'all' };
            break;
        case 'mp_card':
            methods = { creditCard: 'all' };
            break;
    }

    let defaultPaymentOption = {};

    switch (method) {
        case 'mp_pix':
            defaultPaymentOption = { bankTransferForm: true };
            break;
        case 'mp_card':
            defaultPaymentOption = { creditCardForm: true };
            break;
    }

    return {
        initialization: {
            amount: amount,
            payer: {
                email: email,
            }
        },
        customization: {
            paymentMethods: methods,
            visual: {
                hideFormTitle: true,
                defaultPaymentOption: defaultPaymentOption,
            }
        },
        callbacks: {
            onReady: onReady,
            onSubmit: onSubmit,
            onError: onError,
        },
    }
}

export function buildMpStatusConfig(paymentId) {
    return {
        initialization: {
            paymentId: paymentId, // id do pagamento a ser mostrado
        },
        callbacks: {
            onReady: () => {
            },
            onError: (error) => {
                console.error(error);
            },
        },
    };
}