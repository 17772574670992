<template lang="">
    <div>
        <div class="reports-container" v-if="reportsData.temples && seenEnough">
            <h1>Relatório</h1>
            <q-select :options="reallyGetAllEvents"
                v-model="event"
                name="status"
                behavior="menu"
                option-value="id"
                option-label="title"
                style="width: 100%;"
                label="Evento"/>
            <div class="row q-col-gutter-sm">
            <div class="col-6">
                    <q-card>
                    <q-card-section class="bg-blue-6 text-white full-width">
                        <div class="text-h3">{{ reportsData?.temples?.filter((temple) => temple.cnt > 0).length }}</div>
                        <div class="text-subtitle2">Congregações</div>
                    </q-card-section>
                </q-card>
            </div>
            <div class="col-6">
                <q-card>
                    <q-card-section class="bg-green-6 text-white full-width">
                        <div class="text-h3">{{ reportsData?.temples?.reduce((acc, temple) => parseInt(acc) + parseInt(temple.cnt), 0) }}</div>
                        <div class="text-subtitle2">Participantes</div>
                    </q-card-section>
                </q-card>
            </div>
            </div>
            
            <h2>Inscrições por Status</h2>
            <Pie :data="{labels: labelStatus, datasets: [{data: valorStatus, backgroundColor: colorsStatus}]}" :options="{responsive: true}" v-if="labelStatus" />
            <h2>Inscrições pagas por regional</h2>
            <Bar height="300" :data="{labels: labelRegionais, datasets: [{data: valorRegionais, backgroundColor: '#42A5F5', label: 'Inscrições'}]}" :options="{responsive: true}" v-if="labelRegionais"/>
            
            <h2>Total de inscrições por congregação</h2>
            <q-table
                :rows="templesRows"
                :columns="templesColumns"
                row-key="name"
                :dense="$q.screen.lt.sm"
                class="q-table"
            >
                <template v-slot:top-right="props">
                    <q-btn @click="props.toggleFullscreen" flat><font-awesome-icon :icon="['fa', 'expand']"/>&nbsp;&nbsp;&nbsp;Tela cheia</q-btn>
                    <q-btn
                        flat
                        @click="exportTable()"
                        >
                        <font-awesome-icon :icon="['fa', 'download']"/>&nbsp;&nbsp;&nbsp;Download
                    </q-btn>
                </template>


                <template v-slot:top-left>
                    <q-input dense debounce="300" v-model="filter" placeholder="Filtrar">
                    <template v-slot:prepend>
                        <font-awesome-icon :icon="['fa', 'search']"/>
                    </template>
                    </q-input>
                </template>
            </q-table>

            <h2>Inscrições individuais</h2>
            <q-table
                :rows="atendeesPerLocationRows"
                :columns="atendeesPerLocationColumns"
                row-key="name"
                :dense="$q.screen.lt.sm"
                class="q-table"
                style="width: 100%;"
                :no-data-label="atendeePerLocationFilter ? 'Sem dados para a regional/congregação' : 'Selecione uma regional/congregação acima'"
            >
            <template v-slot:top-left>
                <q-select
                    use-input
                    v-model="atendeePerLocationFilter"
                    behavior="menu"
                    option-value="id"
                    option-label="name"
                    label="Regional/congregação"
                    :options="filteredLocation"
                    @filter="filterLocation"
                    style="width: 100%;"
                    @change="updateAtendeePerLocation()">
                </q-select>
            </template>
                <template v-slot:top-right="props">
                    <q-btn @click="props.toggleFullscreen" flat><font-awesome-icon :icon="['fa', 'expand']"/>&nbsp;&nbsp;&nbsp;Tela cheia</q-btn>
                    <q-btn
                        flat
                        @click="exportTableB()"
                        >
                        <font-awesome-icon :icon="['fa', 'download']"/>&nbsp;&nbsp;&nbsp;Download
                    </q-btn>
                </template>
            </q-table>


        </div>
        <div style="text-align: center !important; align-items: center;" class="reports-container" v-else>
            <q-spinner-grid
                color="grey"
                size="4em"
                />
                <div class="loading-text" style="padding-top: 16px;" v-html="message()">
                </div>
        </div>
    </div>
</template>

<script>
import { Pie, Bar } from 'vue-chartjs';
import { exportFile } from 'quasar';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement
} from 'chart.js'
import HumanStringsMixin from '@/mixins/HumanStringsMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

function wrapCsvValue (val, formatFn, row) {
  let formatted = formatFn !== void 0
    ? formatFn(val, row)
    : val

  formatted = formatted === void 0 || formatted === null
    ? ''
    : String(formatted)

  formatted = formatted.split('"').join('""')
  /**
   * Excel accepts \n and \r in strings, but some other CSV parsers do not
   * Uncomment the next two lines to escape new lines
   */
  // .split('\n').join('\\n')
  // .split('\r').join('\\r')

  return `"${formatted}"`
}

import { mapActions, mapGetters } from 'vuex'
    export default {
        name: 'ReportsView',
        mixins: [HumanStringsMixin, NotifyMixin],
        data() {
            return {
                filter: null,
                messageIdx: null,
                seenEnough: true,
                atendeePerLocationFilter: null,
                filteredLocation: [],
                event: null,
            }
        },
        components: {
            Pie, Bar
        },
        methods: {
            ...mapActions('admreports', ['queryReportsData', 'queryMyTemples', 'queryAtendeesPerLocation', 'setEventId']),
            exportTable () {
                let columns = this.templesColumns;
                let rows = this.templesRows;
                // naive encoding to csv format
                const content = [columns.map(col => wrapCsvValue(col.label))].concat(
                rows.map(row => columns.map(col => wrapCsvValue(
                    typeof col.field === 'function'
                    ? col.field(row)
                    : row[ col.field === void 0 ? col.name : col.field ],
                    col.format,
                    row
                )).join(','))
                ).join('\r\n')

                exportFile(
                    'inscricoes-por-congregacao.csv',
                    content,
                    'text/csv'
                );
            },
            exportTableB() {
                let columns = this.atendeesPerLocationColumns;
                let rows = this.atendeesPerLocationRows;
                // naive encoding to csv format
                const content = [columns.map(col => wrapCsvValue(col.label))].concat(
                rows.map(row => columns.map(col => wrapCsvValue(
                    typeof col.field === 'function'
                    ? col.field(row)
                    : row[ col.field === void 0 ? col.name : col.field ],
                    col.format,
                    row
                )).join(','))
                ).join('\r\n')

                exportFile(
                    'inscricoes-por-congregacao.csv',
                    content,
                    'text/csv'
                );
            },
            message() {
                const msgs = [
                    'José interpretava sonhos, e nós interpretamos dados.<br> O relatório está quase pronto!',
                    'Moisés esperou 40 dias pelas tábuas da lei.<br> Nós vamos esperar uns 4 segundos por esse relatório!',
                    'Abrãao esperou muito tempo por seu filho!<br> E nós, esperamos o relatório ficar pronto!',
                    'Assim como foi com Jó, pedimos um pouco de paciência enquanto geramos o relatório!',
                    'Carregando... espere por esse relatório assim como você espera pela volta de Jesus!',
                    'Dê sete voltas ao redor do celular, e grite! Assim o relatório ficará pronto!<br> Ou espere uns 5 segundos...',
                    'Jesus transformava água em vinho. Nós transformamos dados em relatórios!<br> Um momento...',
                    'Sabia que o ieadcdigital.com.br faz relatórios com mais sabedoria que Salomão?',
                ];
                return msgs[this.messageIdx];
            },
            filterLocation(value, update) {
                if (value == '') {
                    update(() => this.filteredLocation = this.processedMyTemples);
                    return;
                }
                update(() => this.filteredLocation = this.processedMyTemples.filter((temple) => temple.name.toLowerCase().indexOf(value.toLowerCase()) > -1));
            },
        },
        mounted() {
            this.queryReportsData();
            this.queryMyTemples();
            this.messageIdx = Math.floor(Math.random() * 7);
            setTimeout(() => this.seenEnough = true, 2000);
        },
        watch: {
            atendeePerLocationFilter() {
                if(this.atendeePerLocationFilter?.id) {
                    this.queryAtendeesPerLocation(this.atendeePerLocationFilter.id);
                }
            },
            event() {
                this.setEventId(this.event.id);
                this.queryReportsData();
                this.queryMyTemples();
            }
        },
        computed: {
            ...mapGetters('admreports', ['reportsData', 'myTemples', 'atendeesPerLocation', 'getEventId']),
            ...mapGetters('events', ['reallyGetAllEvents']),
            labelRegionais() {
                return this.reportsData?.regional?.map((regional) => regional.regional == 'custom' ? 'Visitantes' : regional.regional);
            },
            valorRegionais() {
                return this.reportsData?.regional?.map((regional) => parseInt(regional.cnt));
            },
            labelStatus() {
                return this.reportsData?.status?.map((status) => this.humanStatus(status.status));
            },
            valorStatus() {
                return this.reportsData?.status?.map((status) => parseInt(status.cnt));
            },
            templesColumns() {
                return [
                    {
                        name: 'cnt',
                        required: true,
                        label: 'Nº Insc.',
                        align: 'left',
                        field: row => row.cnt,
                        format: val => `${val}`,
                        sortable: true
                    },
                    {
                        name: 'name',
                        required: true,
                        label: 'Congregação',
                        align: 'left',
                        field: row => row.name,
                        format: val => `${val}`,
                        sortable: true
                    },
                    {
                        name: 'regional',
                        required: true,
                        label: 'Regional',
                        align: 'left',
                        field: row => row.regional,
                        format: val => `${val}`,
                        sortable: true
                    },
                ];
            },
            templesRows() {
                return this.reportsData?.temples?.filter(
                    (temple) => ! this.filter || (
                        temple.regional.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
                            || temple.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
                    )
                );
            },
            atendeesPerLocationRows() {
                return this.atendeesPerLocation;
            },
            atendeesPerLocationColumns() {
                return [
                    {
                        name: 'name',
                        label: 'Inscrito',
                        align: 'left',
                        field: row => row.name,
                        format: val => `${val}`,
                        sortable: true
                    },
                    {
                        name: 'temple_name',
                        required: true,
                        label: 'Congregação',
                        align: 'left',
                        field: row => row.temple_name,
                        format: val => `${val}`,
                        sortable: true
                    },
                    {
                        name: 'regional',
                        required: true,
                        label: 'Regional',
                        align: 'left',
                        field: row => row.regional,
                        format: val => `${val}`,
                        sortable: true
                    },
                    {
                        name: 'strategy',
                        required: true,
                        label: 'Meio de pgto',
                        align: 'left',
                        field: row => this.humanPaymentMethod(row.strategy),
                        format: val => `${val}`,
                        sortable: true
                    },
                    {
                        name: 'status',
                        required: true,
                        label: 'Status',
                        align: 'left',
                        field: row => this.humanStatus(row.status),
                        format: val => `${val}`,
                        sortable: true
                    },
                    {
                        name: 'regional',
                        required: true,
                        label: 'Data da inscrição',
                        align: 'left',
                        field: row => (new Date(row.created_at)).toLocaleDateString('pt-BR', {day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit'}),
                        format: val => `${val}`,
                        sortable: true
                    },
                ];
            },
            processedMyTemples() {
                let regionais = [];
                this.myTemples.forEach((temple) => regionais[temple.regional] = {id: temple.regional, name: "Regional " + temple.regional});
                if (regionais['custom']) {
                    delete regionais['custom'];
                    regionais.push({name: 'Visitantes', id: 'custom'});
                    regionais.push({name: 'Todas as regionais', id: 'all'});
                }
                return [{'name': 'Regionais', 'disable': true}, ...Object.values(regionais), {'name': 'Congregações', 'disable': true}, ...this.myTemples];
            },
            colorsStatus() {
                return this.labelStatus.map(
                    (label) => {
                        switch(label) {
                            case 'Pago':
                                return '#9CCC65';
                            case 'Recusado':
                                return '#90A4AE';
                            case 'Em análise':
                                return '#FF7043';
                            case 'Pendente':
                                return '#FFCA28';
                        }
                    }
                );
            }
        }
    }
</script>

<style lang="scss" scoped>
    .reports-container {
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center ;
        text-align: left;
        gap: 16px;
        max-width: 100vw;
    }

    h1 {
        color: #333;
        line-height: 28px;
        font-size: 30px;
        font-weight: 700;
    }

    h2 {
        color: #333;
        line-height: 24px;
        font-size: 22px;
        font-weight: 700;
        margin: 0px;
        margin-top: 24px;
    }
</style>