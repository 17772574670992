import cpf from '@/helpers/validaCpf';

export default {
  methods: {
    emailRule (val) {
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || 'Insira um email válido';
    },
    required (val) {
      return !! val || 'Campo obrigatório';
    },
    minRule: (min) => (val) => val.length >= min || 'A senha deve ter ao menos 6 caracteres',
    pwdMatch: (shouldBe) => (val) => val === shouldBe || 'As senhas devem ser iguais',
    fullName: (val) => val.indexOf(" ") > -1 || 'Preencha seu nome completo',
    dateTime: (val) => {
      const regex = /^(([0-2]\d|[3][0-1])\/([0]\d|[1][0-2])\/[2][0]\d{2})$|^(([0-2]\d|[3][0-1])\/([0]\d|[1][0-2])\/[2][0]\d{2}\s([0-1]\d|[2][0-3]):[0-5]\d:[0-5]\d)$/;
      return regex.test(val) || "Insira uma data válida";
    },
    validCpf: (val) => cpf(val) || 'Insira um CPF válido',
    exactLength: (reqLength) => (val) => val.length === reqLength || 'O campo deve ter exatamente ' + reqLength + ' caracteres',
  }
}