<template lang="">
    <div>
        <q-banner style="text-align: left;" class="bg-green text-white" v-if="confirmation.status == 'paid'">
            <template v-slot:avatar>
                <font-awesome-icon :icon="['fa','thumbs-up']" style="padding-right: 16px;"/>
            </template>
            <b>Meu pagamento foi bem sucedido. E agora?</b><br>
            Agora basta esperar o dia do evento e abrir a IEADC Digital para mostrar seu ingresso:<br>
            <img v-if="qrUrl" :src="qrUrl" />
        </q-banner>
        <br>
        <div>Método de pagamento: <b>{{humanPaymentMethod}}</b></div>
        <div>Status: <q-badge rounded v-bind="statusBadgeProps" style="padding: 4px 8px;" /></div>
        <br>
        <component :is="getPaymentDetailComponent"
            :confirmation="confirmation"
            :registration="registration"
            @switch-method="cancelRegistration"
            v-if="confirmation.status != 'canceled'" />
            <div v-else>
                Essa tentativa de inscrição foi cancelada.
            </div>

    </div>
</template>
<script>
import CashDetailsVue from './CashDetails.vue';
import FileDetailsVue from './FileDetails.vue';
import MPDetailsVue from './MPDetails.vue';
import OpenDetails from './OpenDetails.vue';
import PixDetailsVue from './PixDetails.vue';
import LinkDetailsVue from './LinkDetails.vue';
import VoucherDetailsVue from './VoucherDetails.vue';
import QRCode from 'qrcode'
import axios from 'axios';
import NotifyMixin from '@/mixins/NotifyMixin';
import { mapGetters } from 'vuex';

export default {
    name: 'RegistrationConfirmation',
    props: ['confirmation'],
    data() {
        return {
            qrUrl: null,
        }
    },
    mixins: [NotifyMixin],
    watch: {
        async registration() {
            if (this.registration?.seq) {
                this.qrUrl = await QRCode.toDataURL(`checkin:${this.registration?.id}`, { errorCorrectionLevel: 'L' });
            }
        }
    },
    methods: {
        async cancelRegistration() {
            try {
                await axios.post(
                    `${process.env.VUE_APP_BACKEND_URL}/v1/registrations/${this.registration.pricing_tiers.events.id}/cancel-registration`,
                    {},
                    {
                        headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'application/json' }
                    }
                );

                this.$q.notify({
                        message: "Inscrição cancelada com sucesso!",
                        color: 'positive',
                        position: 'bottom',
                        timeout: 5000,
                    });
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);
            } catch (e) {
                this.$q.notify({
                    message: e.response.data.message,
                    color: 'negative',
                    position: 'bottom',
                    timeout: 5000,
                });
            }
        }
    },
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
        registration() {
            return this.$store.getters['registrations/getSingleRegistration'](this.confirmation.registration_id)
        },
        getPaymentDetailComponent() {
            switch(this.confirmation.strategy) {
                case 'open':
                    return OpenDetails;
                case 'mp_card':
                case 'mp_pix':
                    return MPDetailsVue;
                case 'file':
                    return FileDetailsVue;
                case 'cash':
                    return CashDetailsVue;
                case 'link':
                    return LinkDetailsVue;
                case 'voucher':
                    return VoucherDetailsVue;
            }            
            return PixDetailsVue;
        },
        statusBadgeProps() {
            switch(this.confirmation.status) {
                case 'paid':
                    return {color: 'green', 'label': 'Pago'}
                case 'analysis':
                    return {color: 'blue', 'label': 'Em análise'};
                case 'pending':
                    return {color: 'blue', 'label': 'Pendente'};
                case 'expired':
                    return {color: 'red', 'label': 'Expirado'}
                case 'rejected':
                    return {color: 'red', 'label': 'Rejeitado'}
                case 'canceled':
                    return {color: 'red', 'label': 'Cancelado'}
            }

            return {color: 'blue', 'label': 'Em processamento...'}
        },
        humanPaymentMethod() {
            switch(this.confirmation.strategy) {
                case 'mp_card':
                    return 'Cartão de crédito';
                case 'mp_pix':
                    return 'PIX';
                case 'file':
                    return 'Comprovante PIX';
                case 'cash':
                    return 'Pagamento presencial';
                case 'voucher':
                    return 'Voucher';
                case 'open':
                    return 'Gratuito';
                case 'link':
                    return 'Pagseguro';
                default:
                    return "PM";
            }
        }
    }
}
</script>
<style lang="">
    
</style>