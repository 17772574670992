<template>
    <div>
        <q-list>
            <q-item v-for="(file, index) in files" :key="index" :clickable="!file.loading" @click="getFileUrl(file)"
                :disable="file.loading">
                <q-item-section avatar>
                    <font-awesome-icon :icon="file.loading ? ['fa', 'loading'] : ['fa', 'file']" />
                </q-item-section>
                <q-item-section :class="{ 'text-grey': file.loading }">{{ file.name }}</q-item-section>
                <q-item-section side>
                    <q-spinner v-if="file.loading" />
                </q-item-section>
            </q-item>
        </q-list>

        <q-dialog v-model="modalOpen" full-width full-height>
            <q-card>
                <q-card-actions align="left">
                    <font-awesome-icon :icon="['fa', 'close']" style="margin-right: 12px;" :size="'2x'" @click="modalOpen = false" />
                    {{ currentFile.name }}
                </q-card-actions>
                <q-card-section style="padding: 0px; height: calc(100% - 50px);">
                    <iframe :src="fileUrl" style="width: 100%; height: 100%;" @load="onIframeLoad" frameBorder="0"/>
                    <q-spinner v-if="iframeLoading" />
                </q-card-section>
            </q-card>
        </q-dialog>
    </div>
</template>
  
<script>
import NotifyMixin from '@/mixins/NotifyMixin';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
    mixins: [NotifyMixin],
    props: {
        files: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            modalOpen: false,
            fileUrl: '',
            iframeLoading: false,
            currentFile: null
        };
    },
    methods: {
        getFileUrl(file) {
            if (!file.loading) {
                this.currentFile = file;
                this.iframeLoading = true;
                axios.get(`${process.env.VUE_APP_BACKEND_URL}/v1/files`, { params: { type: 'eventFile', filename: file.path }, headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'application/json' } }).then(response => {
                    this.fileUrl = response.data.signedUrl;
                    this.modalOpen = true;
                    file.loading = false;
                }).catch(error => {
                    console.error(error);
                });
            }
        },
        onIframeLoad() {
            this.iframeLoading = false;
        }
    },
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
    }
};
</script>
  