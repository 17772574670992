<template lang="">
    <div>
        <input @blur="dirty = true" ref="pac" :placeholder="placeholder" :disabled="loading" :class="{'invalid': dirty && required && !!! address}"/>
        <small v-if="dirty && required && !!! address" class="text-red">O endereço é obrigatório.</small>
    </div>
</template>
<script>
export default {
    props: ['address', 'lat', 'lng', 'required'],
    emits: ['update:address', 'update:lat', 'update:lng'],
    data() {
        return {
            loading: true,
            dirty: false,
        }
    },
    computed: {
        placeholder() {
            return this.loading ? "Carregando Google Maps..." : "Buscar endereço";
        }
    },
    mounted() {
        // https://maps.googleapis.com/maps/api/geocode/json?address=Ulisses+VIeira+297&key=AIzaSyB-H6QbUqGIVmJCURFjxHUekG1clUGo8dY
        const plugin = document.createElement("script");
        plugin.setAttribute(
            "src",
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyB-H6QbUqGIVmJCURFjxHUekG1clUGo8dY&libraries=places"
        );
        document.head.appendChild(plugin);
        setTimeout(() => {
            const input = this.$refs.pac;
            if (this.address) {
                input.value = this.address;
            }
            const options = {
                fields: ["address_components", "geometry", "icon", "name"],
            };
            // eslint-disable-next-line
            const searchBox = new google.maps.places.SearchBox(input, options);
            searchBox.addListener("places_changed", () => {
                const place = searchBox.getPlaces()[0];
                this.$emit('update:lat', place.geometry.location.lat());
                this.$emit('update:lng', place.geometry.location.lng());
                this.$emit('update:address', place.formatted_address);
            });
            this.loading = false;
        }, 2000)
        
    }
}
</script>
<style lang="scss" scoped>
    input {
        width: 100%;
        height: 46px;
        background: transparent;
        border: 0px solid;
        border-bottom: 1px solid grey;
        outline-width: 0;
        transition: 0.3s all;
    }

    input:focus {
        outline-width: 0;
        border: 0px solid;
        border-bottom: 2px solid #027BE3;
    }

    input.invalid {
        outline-width: 0;
        border: 0px solid;
        border-bottom: 2px solid red;
    }
</style>