<template lang="">
    <div id="profile-container">
        <img @click="viewProfile()" v-if="! hideProfile && authenticatedUser" class="profile" referrerpolicy="no-referrer" :src="authenticatedUser?.image ?? fallbackProfilePic"/>
        <div>
            <h1>{{ authenticatedUser.name }} <font-awesome-icon class="edit-icon" :icon="['fa', 'edit']" /></h1>
            <h2>{{ authenticatedUser.email }} </h2>
        </div>
        <router-link to="/auth/reset-password">Mudar senha</router-link>
    </div>  
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        name: 'ProfileView',
        methods: {
        
        },
        mounted() {
        },
        computed: {
            ...mapGetters('auth', ['authenticatedUser']),
            fallbackProfilePic() {
                return "https://ui-avatars.com/api/?background=0D8ABC&color=fff&name="+this.authenticatedUser?.name;
            }
        }
    }
</script>

<style lang="scss" scoped>
    #profile-container {
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center ;
        text-align: center;
        gap: 16px;
    }

    .profile {
        border-radius: 100px;
        height: 140px;
    }

    .edit-icon {
        font-size: 50%;
        position: relative;
    }

    h1 {
        color: #333;
        line-height: 30px;
        font-size: 28px;
        font-weight: 700;
        margin: 0;
    }

    h2 {
        font-weight: 500;
        color: #666;
        margin: 0;
        font-size: 16px;
        line-height: 18px;
    }
</style>