<template>
  <div class="login">
    <img src="@/assets/img/ieadc-digital.png"/>
    <div class="social-login" v-if="socialLogin">
      <q-btn :disabled="socialDisabled" class="social-button" outline @click="authFlow('google')">
        <font-awesome-icon :icon="['fab', 'google']"/>&nbsp;&nbsp;&nbsp;Login com Google
      </q-btn>
      <br><br>
      <span>Caso enfrente problemas com o Login com Google, faça login com usuário e senha direto no ieadcdigital.com.br. Se necessário, <router-link to='/auth/forgot'>redefina sua senha aqui</router-link>.</span>
      <p v-html="socialDisabled"></p>
    </div>
    <div id="user-pwd-login" v-else>
      <q-form @submit="authFlow(showPasswordField ? 'pwd' : 'checkEmail')">
      <q-input :rules="[required, emailRule]" v-model="email" label="Email" type="email" />
      <q-input :rules="[required, minRule(6)]" v-model="pwd" v-if="showPasswordField" type="password" label="Senha" />
      <!-- <q-btn outline v-if="! showPasswordField" label="Continuar &raquo;" type="submit" style="margin-top: 16px;"/> -->
      <q-btn color="white" text-color="blue" no-shadow label="Login &raquo;" type="submit" style="margin-top: 16px;"/> <!-- v-else -->
      <br><br>
      <q-btn :disabled="socialDisabled" class="social-button" outline @click="authFlow('google')">
        <font-awesome-icon :icon="['fab', 'google']"/>&nbsp;&nbsp;&nbsp;Login com Google
      </q-btn>
      <p v-html="socialDisabled"></p>
      <br><br>
      Não tem uma conta? <router-link to="/auth/signup"><b>Criar uma conta!</b></router-link>
      <br><br>
      <router-link to='/auth/forgot'>Esqueceu sua senha?</router-link>
    </q-form>
    </div>
    <small class="switch-to-pwd">
      <b>Precisa de ajuda?</b><br>
      Envie um email para: suporte<span>@</span>umadc.digital
    </small>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as Yup from 'yup';
import Supabase from '@/supabaseInit';
import ValidationMixin from '@/mixins/ValidationMixin';
import { useQuasar } from 'quasar';

/*const emailOnlySchema = Yup.object().shape({
  email: Yup.string().email().required(),
});*/

const loginSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().min(6).required(),
});

/* eslint-disable */
export default {
  name: 'LoginView',
  mixins: [ValidationMixin],
  mounted(){
    const $q = useQuasar();

    try {
      if (this.$route.query.method == 'email') {
        this.socialLogin = false;
      }
    } catch (e) {
      console.error(e);
    }
    
    if (window.navigator.userAgent.indexOf("Instagram") > -1) {
      this.socialLogin = false;
      this.socialDisabled = window.navigator.userAgent.indexOf("iPhone") > -1
        ? "O login com o Google <b>não é suportado</b> a partir do Instagram.<br> Caso queira fazer Login com Google, acesse diretamente pelo Safari. <br><br><small>Clique nos \"...\" no canto superior direito, e depois em \"Abrir no navegador do sistema\"</small>"
        : "O login com o Google <b>não é suportado</b> a partir do Instagram.<br> Caso queira fazer Login com Google, acesse diretamente pelo navegador do celular (Chrome). <br><br><small>Clique nos \"...\" no canto superior direito, e depois em \"Abrir no Chrome\"</small>"
    }
  },
  data() {
    return {
      socialLogin: false,
      email: null,
      pwd: null,
      showPasswordField: true,
      mustBeSocial: false,
      failedOnce: false,
    }
  },
  computed: {
    switchMethodText() {
      return this.socialLogin
        ? "Fazer login com usuário e senha"
        : "Fazer login com Google";
    },
    ...mapGetters('auth', ['authenticatedUser']),
  },
  methods: {
    ...mapActions('auth', ['setAuthenticatedUser']),
    authFlow(type) {
      try {
        switch(type) {
          case 'google':
            this.handleOAuth('google');
            break;
          /*case 'apple':
            this.doAppleAuth(auth);
            break;
          case 'checkEmail':
            emailOnlySchema.validateSync({email: this.email});
            this.doCheckEmail(this.email);
            break;*/
          case 'pwd':
            loginSchema.validateSync({email: this.email, password: this.pwd}, {abortEarly: false});
            this.doPwdAuth(this.email, this.pwd);
            break;
        }
      } catch (e) {
        this.errors = {};
        e.inner.forEach((inner) => {
          this.errors[inner.path] = inner.message;
        });
      }
    },
    /*async doCheckEmail(email) {
      let response = null;
      
      const {data, error} = await Supabase.from('user_auth_data')
        .select('provider')
        .eq('email', await this.hash(email));

      if (data.length > 0) {
        response = data[0].provider;
      }

      this.handleCheckEmail(response);
    },*/
    handleCheckEmail(response) {
      if (response == 1) {
        this.socialLogin = true;
        this.mustBeSocial = "Google";
      /*} else if (response == 2) {
        this.socialLogin = true;
        this.mustBeSocial = "Apple";*/
      } else if (response == 3) {
        this.showPasswordField = true;
      } else if (! response) {
        this.$router.push({path: '/auth/signup', query: {'email': this.email, 'redirectTo': this.$route.query.redirectTo}})
      }
    },
  doGoogleAuth(auth) {
      this.handleOAuth(auth);
    },
    async handleOAuth(provider) {
      await Supabase.auth.signInWithOAuth({
        provider: provider,
        options: {
          redirectTo: process.env.VUE_APP_URL,
        }
      });
    },
    doPwdAuth() {
      Supabase.auth.signInWithPassword({
        email: this.email,
        password: this.pwd
      })
        .then((result) => this.handleLoginResult(result))
        .catch((result) => console.error(result));
    },
    handleLoginResult({data, error}) {
      if (error) {
        if (error.message == "Email not confirmed") {
          this.$q.notify({
            type: 'negative',
            html: true,
            position: 'top',
            message: "Você ainda não confirmou seu email! <br> Por favor, confira sua caixa de entrada e pasta de spam.",
          })
        } else {
          this.$q.notify({
            type: 'negative',
            position: 'top',
            html: true,
            message: `Email ou senha inválidos. <a href='/auth/forgot?email=${this.email}'>Esqueceu sua senha?</a>`,
          })
        }
        this.failedOnce = true;
        return;
      }

      // this.setAuthenticatedUser(data);
      this.$router.push({'path': this.$route.query.redirectTo ?? '/'});
  },
    async hash(email) {
      var buffer = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(email));
      var chars = Array.prototype.map.call(new Uint8Array(buffer), ch => String.fromCharCode(ch)).join('');
      return window.btoa(chars);
    }
  }
}
</script>

<style lang="scss" scoped>
  .login {
    padding: 30px;
    height: 100vh;
    width: 100vw;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 48px;

    img {
      display: inline;
      width: 50vw;
      max-width: 300px;
    }

    #user-pwd-login {
      width: 80vw;
      max-width: 500px;
    }

    .social-login {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;

      #social-expl {
        font-size: 18px;
        font-weight: 500;
      }
      
      .social-button {
        padding: 16px 32px;
        width: 240px;
      }
    }
  }

.switch-to-pwd {
  font-weight: 300;
  font-size: 12px;
}

a {
  color: white;
}
</style>
