<template lang="">
    <div>
        
    </div>
</template>
<script>
import Supabase from '@/supabaseInit';

export default {
    name: 'OAuthCallbackView',
    async mounted() {
        Supabase.auth.getUser()
        .then(async (user) => {
            if (! user.data.user.user_metadata.welcome) {
                this.$router.push({path: '/auth/welcome'});
                return;
            } 

            this.$router.push({path: '/'});
        })
        .catch();
    },
    methods: {
        async hash(email) {
            var buffer = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(email));
            var chars = Array.prototype.map.call(new Uint8Array(buffer), ch => String.fromCharCode(ch)).join('');
            return btoa(chars);
        },
        getProviderId(provider) {
            const providers = {
                'google': 1,
                'apple': 2
            }
            return providers[provider];
        }
    }
}
</script>
<style lang="">
    
</style>