<template lang="">
    <div class="tags-form">
        <div class="title-1">
            {{ formTitle }}
        </div>
        <q-form @submit="save">
            <q-input :rules=[required] v-model="tag.name" label="Nome"/>
            <div class="color-container">
                <q-color v-model="tag.color" no-header no-footer class="my-picker" />
            </div>
            <div class="action-buttons-container">
                <q-btn class="primary" @click="cancel">Cancelar</q-btn>
                <q-btn class="primary" type="submit" color="primary">Salvar</q-btn>
            </div>
        </q-form>
    </div>
</template>
<script>
import supabaseInit from "@/supabaseInit";
import { mapActions } from 'vuex';
import ValidationMixin from '@/mixins/ValidationMixin';
import * as Yup from 'yup';
import NotifyMixin from "@/mixins/NotifyMixin";

const tagSchema = Yup.object().shape({
    name: Yup.string().required(),
    color: Yup.string().matches('^#(?:[0-9a-fA-F]{3}){1,2}$').required()
});

export default {
    data() {
        return {
            tag: {
                id: null,
                name: null,
                color: "#3080d4",
            }
        }
    },
    props: ['model'],
    mixins: [
        ValidationMixin,
        NotifyMixin
    ],
    mounted(){
        if (this.model) this.tag = this.model;
    },
    /* eslint-disable */
    methods: {
        ...mapActions(['hideOverlay']),
        ...mapActions('tags', ['pushTag']),
        async save() {
            const data = {
                name: this.tag.name,
                color: this.tag.color,
            };

            if (this.tag.id)
                data.id = this.tag.id;

            try {
                tagSchema.validateSync(data);
            } catch (e) {
                console.error(e);
                return;
            }
            const newTag = await this.withLoader(() => supabaseInit.from('tags').upsert(data).select());

            if (! data.id) {
                this.pushTag(newTag.data[0]);
                this.$emit('selected', newTag.data[0]);
            }
            this.hideOverlay();
        },
        cancel() {
            this.hideOverlay();
        }
    },
    computed: {
        formTitle() {
            return this.tag.id == null ? 'Nova tag' : 'Editar tag';
        },
    },
}
</script>
<style lang="scss" scoped>
    .tags-form {
        padding: 30px;
        text-align: left;
    }

    .title-1 {
        color: #333;
        line-height: 30px;
        font-size: 28px;
        font-weight: 700;
    }

    .action-buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 16px 0px;
    }

    .color-container {
        margin-top: 16px;
    }
</style>