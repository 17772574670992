<template>
  <router-view/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Supabase from './supabaseInit';

export default {
  methods: {
      ...mapActions('auth', ['setAuthenticatedUser', 'supabaseSignedOut'])
  },
  async beforeMount() {
    if (window.location.href.includes('umadc.digital')) {
      window.location.href = window.location.href.replace('app.umadc.digital', 'inscricoes.ieadcdigital.com.br');
      return;
    }

    Supabase.auth.onAuthStateChange((event, session) => {
      if (event == 'SIGNED_IN') this.setAuthenticatedUser(session)
      else if (event == 'SIGNED_OUT') this.supabaseSignedOut()
      else if (event == 'TOKEN_REFRESHED') this.setAuthenticatedUser(session)
      else if (event == 'PASSWORD_RECOVERY') this.$router.push('/auth/reset-password');

      if (session && session.user && ! session.user.user_metadata.welcome) this.$router.push('/auth/welcome');
    });
  },
  computed: {
    ...mapGetters('auth', ['authenticatedUser'])
  },
};
</script>

<style lang="scss">
</style>
