<template>
    <div class="signup">
        <div id="signup-form">
            <q-form  id="form" @submit="updatePwd">
                <q-input :rules="[required, emailRule]" v-model="email" label="Email" />
                <q-input :rules="[required, minRule(6)]" v-model="pwd" label="Nova Senha" />
                <q-btn outline :loading="loading" label="Mudar senha &raquo;" type="submit"
                    style="margin-top: 16px; float: right;" /><br>
            </q-form>
        </div>
    </div>
</template>

<script>
import ValidationMixin from '@/mixins/ValidationMixin';
import { mapGetters } from 'vuex';
import axios from 'axios';
import NotifyMixin from '@/mixins/NotifyMixin';

export default {
    name: 'PwdReset',
    mixins: [ValidationMixin, NotifyMixin],
    data() {
        return {
            email: null,
            loading: false,
            pwd: null,
        }
    },
    methods: {
        async updatePwd() {
            this.loading = true;
            const signUpData = {
                email: this.email,
                pwd: this.pwd,
            };

            try {
                await axios.post(
                    `${process.env.VUE_APP_BACKEND_URL}/v1/admin/pwd-reset`,
                    signUpData,
                    { 
                        headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'application/json' }
                    }
                );

                this.$q.notify({
                    message: "Senha alterada com sucesso!",
                    color: 'positive',
                    position: 'bottom',
                    timeout: 3000,
                });
            } catch (e) {
                // eslint-disable-next-line
                window.errorHandler.report("Erro no pwd reset! " + JSON.stringify(signUpData) + " // " + JSON.stringify(e));

                this.$q.notify({
                    message: e.response.data.error,
                    color: 'negative',
                    position: 'bottom',
                    timeout: 5000,
                });
            }

            this.loading = false;
            this.email = null;
            this.pwd = null;
        },
    },
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
    },
}
</script>

<style lang="scss" scoped>
.signup {
    transition: 1s all;
    padding: 30px;
    width: 100vw;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 48px;
    text-align: left;

    img {
        display: inline;
        width: 33vw;
        max-width: 200px;
    }

    #signup-form {
        width: 80vw;
        max-width: 500px;
    }

    span {
        font-weight: 300;
        letter-spacing: -1px;
        font-size: 20px;
        padding-bottom: 40px;
        opacity: 1;
        transition: 1s all;
    }
}

#form {
    opacity: 1;
    transition: 1s all;
}

.transp {
    opacity: 0 !important;
}
</style>
