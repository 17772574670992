<template lang="">
    <div class="card">
        <div class="card--banner" v-if="image" :style="bgImage">
        </div>
        <div class="card--body">
          <slot name="body"></slot>
        </div>
    </div>
</template>
<script>

export default {
    name: "Card",
    props: {
      image: String,
    },
    computed: {
      bgImage() {
        return {
          "background-image": `url('https://storage.googleapis.com/umadc-digital-public/${this.image}')`
        }
      }
    }
}
</script>
<style lang="scss" scoped>
.card {
    &.no-scroll {
      margin: 16px;
      padding: 8px;
    }

    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 12px;
    background-color: #fff;
    overflow: hidden;
    min-width: 85%;
    scroll-snap-align: start;

    &--banner {
      height: 150px;
      background-size: cover;
      background-position: center;
      margin-bottom: 8px;
    }

    &--body {
      padding: 16px;
    }
  }
</style>