<template>
  <div v-if="badges.length > 0">
    <BadgeLabel
      v-for="badge in badges"
      :key="badge.registration_id"
      :name="badge.registrations.users.name"
      :temple="badge.registrations.users.temples.name"
      :code="badge.registration_id"
      :no-print="true"
      />
  </div>
</template>

<script>
import BadgeLabel from '@/components/BadgeLabel';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'AllBadges',
  components: { BadgeLabel },
  data() {
    return {
      badges: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['authenticatedUser']),
  },
  async mounted() {
    let data = await axios.get(
        `${process.env.VUE_APP_BACKEND_URL}/v1/admin/badge-confirmations`,
        { 
            headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'application/json' }
        }
    );
    this.badges = data.data.data;
    console.log(this.badges);
  }
}
</script>

<style lang="scss" scoped>

</style>
