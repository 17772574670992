import { createRouter, createWebHistory } from 'vue-router'

import MainLayout from '../views/layouts/MainLayout.vue'
import FullScreenLayout from '../views/layouts/FullScreenLayout.vue'

import HomeView from '../views/HomeView.vue'
// import CalendarView from '../views/CalendarView.vue'
import AllEventsView from '../views/AllEventsView.vue'
import ProfileView from '../views/ProfileView.vue'

import LoginView from '../views/LoginView.vue'
import SignUpView from '../views/SignUpView.vue'
import AllBadges from '../views/AllBadges.vue'
import OAuthCallbackView from '../views/OAuthCallbackView.vue'
import ResetPasswordView from '../views/ResetPasswordView.vue'
import ForgotPasswordView from '../views/auth/ForgotPasswordView.vue'
import EmailConfirmationView from '../views/auth/EmailConfirmationView.vue'

import BadgeLabel from '@/components/BadgeLabel';

//import EventsList from '../views/admin/events/EventsList.vue'
import EventsForm from '../views/admin/events/EventsForm.vue'

import WelcomeView from '@/views/WelcomeView.vue'
import EventWelcomeView from '@/views/EventWelcomeView.vue'

import CheckoutView from '@/views/checkout/CheckoutView.vue'

import MyRegistrationsView from '@/views/registration/MyRegistrationsView.vue'

import FastpassView from '../views/static/FastpassView.vue'

import EventView from '@/views/event/EventView.vue';

import AdminConfirmationsView from '@/views/admin/registrations/AdminConfirmationsView';

import CheckinScannerView from '@/views/admin/checkin/CheckinScannerView';
import ReportsView from '@/views/admin/ReportsView';
import SinglePageSignUpView from '@/views/admin/users/SinglePageSignUpView';
import PwdReset from '@/views/admin/users/PwdReset';

const routes = [
  {
    path: '/',
    name: 'mainlayout',
    component: MainLayout,

    children: [
      {
        path: '/fastpass',
        name: 'fastpass',
        component: FastpassView
      },
      {
        path: '/',
        name: 'home',
        component: HomeView,
      },
      {
        path: 'profile/',
        name: 'profile',
        component: ProfileView,
      },
      {
        path: '/all',
        name: 'all-events',
        component: AllEventsView,
      },
      {
        path: '/events/:id',
        name: 'event',
        component: EventView,
      },
      {
        path: '/admin/single-page-signup',
        component: SinglePageSignUpView
      },
      {
        path: '/admin/pwd-reset',
        component: PwdReset
      },
      {
        path: '/admin/events/new',
        component: EventsForm
      },
      {
        path: '/admin/events/:id',
        component: EventsForm
      },
      {
        path: '/admin/checkin',
        component: CheckinScannerView
      },
      {
        path: '/checkout/:eventId/:tierId',
        component: CheckoutView,
      },
      {
        path: '/my-registrations',
        component: MyRegistrationsView,
      },
      {
        path: '/my-registrations/:registrationId',
        component: MyRegistrationsView,
      },
      {
        path: '/admin/registrations',
        component: AdminConfirmationsView,
      },
      {
        path: '/admin/reports',
        component: ReportsView,
      },
    ]
  },
  {
    path: '/admin/badge',
    component: BadgeLabel,
  },
  {
    path: '/admin/all-badge',
    component: AllBadges,
  },
  {
    path: '/',
    name: 'fslayout',
    component: FullScreenLayout,
    children: [
      {
        name: 'auth',
        path: '/',
        children: [
          {
            path: '/auth/login',
            name: 'login',
            component: LoginView,
          },
          {
            path: '/auth/signup',
            name: 'signup',
            component: SignUpView,
          },
          {
            path: '/auth/oauth',
            name: 'oauth',
            component: OAuthCallbackView,
          },
          {
            path: '/auth/welcome',
            name: 'welcome',
            component: WelcomeView,
          },
          {
            path: '/event-welcome',
            name: 'eventWelcome',
            component: EventWelcomeView,
          },
          {
            path: '/auth/reset-password',
            name: 'reset-password',
            component: ResetPasswordView,
          },
          {
            path: '/auth/forgot',
            name: 'forgot-password',
            component: ForgotPasswordView,
          },
          {
            path: '/auth/confirmation',
            name: 'confirmation',
            component: EmailConfirmationView,
          },
        ]
      }
    ]
  },
  /*{
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" *//* '../views/AboutView.vue')
}*/
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



export default router
