<template lang="">
    <div>
        <q-stepper
                v-model="step"
                vertical
                flat
                color="primary"
                animated
                style="text-align: left;"
                >
                <q-step
                    :name="1"
                    title="Como funciona?"
                    :done="step > 0"
                >{{sending}}
                O pagamento presencial significa que você deverá efetuar o pagamento no momento do evento (ou antes, caso indicado pela organização do evento), quando nossa equipe estiver presente. <b>Caso o pagamento seja feito em espécie, por favor esteja preparado para trazer o valor exato em dinheiro, pois não poderemos oferecer troco.</b>
<br><br>
                Ao escolher o pagamento presencial, você concorda em efetuar o pagamento no local do evento (ou antes, caso indicado pela organização do evento). Caso haja algum problema com o pagamento ou você não esteja presente para realizá-lo, <b>a reserva ou participação no evento pode ser cancelada</b>.
                <div style="margin-top: 16px;" v-if="confirmation.status == 'pending'">
                        <small>Para trocar o método de pagamento, cancele a inscrição atual e inicie uma nova.</small><br>
                        <q-btn color="red" @click="$emit('switch-method')">CANCELAR INSCRIÇÃO</q-btn>
                    </div>
                </q-step>
                <q-step
                    :name="2"
                    title="Resultado"
                    :done="step > 1"
                >
                    <div v-if="confirmation.status == 'rejected'">
                        No momento do evento não foi possível pagar: <b>{{confirmation.response_data?.reason}}</b><br><br>
                        Inicie uma nova inscrição para pagar com outro método.
                    </div>
                    <div v-else>
                        Pagamento <b>realizado</b>!
                    </div>
                </q-step>
        </q-stepper>
    </div>
</template>
<script>

export default {
    'name': 'CashDetails',
    props: ['confirmation'],
    computed: {
        step() {
            switch(this.confirmation.status) {
                case 'pending':
                    return 1;
                case 'rejected':
                case 'paid':
                    return 2;
            }
            return 1;
        }
    }
}
</script>
<style lang="">
    
</style>