<template>
    <div class="signup">
        <div id="signup-form">
            <q-form  id="form" @submit="createAccount">
                <q-input :rules="[required, emailRule]" v-model="email" label="Email" />
                <q-select v-model="tier" behavior="menu" option-value="value" option-label="label" label="Evento"
                    :options="events" :rules="[required]"></q-select>
                <q-btn outline :loading="loading" label="Entrar &raquo;" type="submit"
                    style="margin-top: 16px; float: right;" /><br>
            </q-form>
        </div>
    </div>
</template>

<script>
import ValidationMixin from '@/mixins/ValidationMixin';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import NotifyMixin from '@/mixins/NotifyMixin';

export default {
    name: 'SinglePageSignUpView',
    mixins: [ValidationMixin, NotifyMixin],
    data() {
        return {
            email: null,
            loading: false,
            tier: null,
        }
    },
    methods: {
        ...mapActions(['queryTemples']),
        ...mapActions('events', ['queryAllAdminEvents']),
        async createAccount() {
            this.loading = true;
            const signUpData = {
                email: this.email,
                tier: this.tier.value,
            };

            try {
                await axios.post(
                    `${process.env.VUE_APP_BACKEND_URL}/v1/admin/single-page-signup`,
                    signUpData,
                    { 
                        headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'application/json' }
                    }
                );

                this.$q.notify({
                    message: "Cadastro criado com sucesso!",
                    color: 'positive',
                    position: 'bottom',
                    timeout: 3000,
                });
            } catch (e) {
                // eslint-disable-next-line
                window.errorHandler.report("Erro no signup! " + JSON.stringify(signUpData) + " // " + JSON.stringify(e));

                this.$q.notify({
                    message: e.response.data.error,
                    color: 'negative',
                    position: 'bottom',
                    timeout: 5000,
                });
            }

            this.loading = false;
            this.email = null;
            // this.tier = null;
        },
        async hash(email) {
            var buffer = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(email));
            var chars = Array.prototype.map.call(new Uint8Array(buffer), ch => String.fromCharCode(ch)).join('');
            return btoa(chars);
        },
        filterTemples(value, update) {
            if (value == '') {
                update(() => this.filteredTemples = this.temples);
                return;
            }
            update(() => this.filteredTemples = this.temples.filter((temple) => temple.name.toLowerCase().indexOf(value.toLowerCase()) > -1));
        },
    },
    mounted() {
        this.queryTemples();
        this.queryAllAdminEvents();
    },
    computed: {
        ...mapGetters(['temples']),
        ...mapGetters('auth', ['authenticatedUser']),
        events() {
            if(this.$store.getters['events/getAllAdminEvents']) {
                return this.$store.getters['events/getAllAdminEvents']
                    .filter((item) => item.pricing_tiers.length > 0)
                    .map((item) => item.pricing_tiers.map((tier) => ({ value: tier.id, label: item.title + " - " + tier.name })))
                    .flat();
            }
            return [];
        },
    },
    watch: {
        fromOtherChurch(val) {
            setTimeout(() => val && this.$refs.otherChurch.focus(), 100);
            val ? this.congregacao = null : this.otherChurch = null;
        }
    }
}
</script>

<style lang="scss" scoped>
.signup {
    transition: 1s all;
    padding: 30px;
    width: 100vw;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    gap: 48px;
    text-align: left;

    img {
        display: inline;
        width: 33vw;
        max-width: 200px;
    }

    #signup-form {
        width: 80vw;
        max-width: 500px;
    }

    span {
        font-weight: 300;
        letter-spacing: -1px;
        font-size: 20px;
        padding-bottom: 40px;
        opacity: 1;
        transition: 1s all;
    }
}

#form {
    opacity: 1;
    transition: 1s all;
}

.transp {
    opacity: 0 !important;
}
</style>
