import Supabase from '@/supabaseInit';
import moment from 'moment';
import axios from 'axios';

export default {
    namespaced: true,
    state: () => ({
        pendingConfirmations: [],
        invalidConfirmations: [],
    }),
    mutations: {
        setPendingConfirmations(state, confirmations) {
            state.pendingConfirmations = confirmations;
        },
        pushPendingConfirmations(state, confirmations) {
            /*const tempConfirmations = state.pendingConfirmations;
            state.pendingConfirmations = [];
            confirmations.forEach((confirmation) => {
                tempConfirmations[confirmation.id] ?? (tempConfirmations[confirmation.id] = confirmation);
            });
            state.pendingConfirmations = tempConfirmations;*/
            const newConfirmations = [];
            confirmations.forEach((confirmation) => {
                newConfirmations[confirmation.id] = confirmation;
            });
            state.pendingConfirmations = newConfirmations;
        },
        updateSinglePendingConfirmation(state, updatedConfirmation) {
            state.pendingConfirmations[updatedConfirmation.id] = updatedConfirmation;
        },
        removeConfirmationById(state, id) {
            const oldArray = state.pendingConfirmations;
            const idToSlice = oldArray.findIndex(
                (confirmation) => confirmation && confirmation.id == id)
            ;
            state.pendingConfirmations = oldArray.toSpliced(idToSlice, 1);
            
        }
    },
    /* eslint-disable */
    actions: {
        async queryConfirmations({commit, rootState}, filters) {
            const supabaseConfirmations = await axios.get(
                `${process.env.VUE_APP_BACKEND_URL}/v1/admin/confirmations`,
                { 
                    params: filters,
                    headers: { Authorization: `Bearer ${rootState.auth.authenticatedUser.token}`, 'Content-Type': 'application/json' }
                }
            );

            commit('pushPendingConfirmations', supabaseConfirmations.data);
        },
        async updateSingleConfirmation({commit}, id) {
            let updatedConfirmation = await Supabase
                .from('registration_confirmations')
                .select('id,registration_id,request_data,strategy,status,registrations(user_id,tier_id,frozen_value,status,pricing_tiers(id,name))')
                .eq('status', 'pending')
                .eq('id', id)
                .limit(1);

            commit('updateSinglePendingConfirmation', updatedConfirmation.data[0]);
        },
        async updateConfirmationStatus({commit, rootState}, {id, status, reason}) {
            let body = {};

            if (reason) {
                body.reason = reason;
            }

            await axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/v1/admin/confirmations/${id}/${status == 'paid' ? 'approve' : 'reject'}`,
                body,
                { headers: { Authorization: `Bearer ${rootState.auth.authenticatedUser.token}`, 'Content-Type': 'application/json' } }
            );

            commit('removeConfirmationById', id);
        },
    },
    getters: {
        getAllPendingConfirmations(state) {
            return Object.values(state.pendingConfirmations)
                .filter((conf)=>conf)
                .sort((confirmationA, confirmationB) => moment(confirmationA.created_at) - moment(confirmationB.created_at));
        },
        getSinglePendingConfirmation: (state) => (confirmationId) => {
            return state.pendingConfirmations[confirmationId];
        },
    }
}