<template lang="">
    <span class="badge" :style="style">
        #{{ cValue }}
    </span>
</template>
<script>
export default {
    name: "AppBadge",
    props: {
        value: {
            type: [Object, Array, String],
        },
        labelKey: {
            type: [String, Number],
        },
        color: {
            type: [String]
        },
        colorKey: {
            type: [String]
        },
        borderColor: {
            type: [String]
        },
        transparent: {
            type: [Boolean],
            default: false
        }
    },
    computed: {
        cValue() {
            return this.labelKey ? this.value[this.labelKey] : this.value;
        },
        style() {
            return {
                'background-color': this.color ? this.color : '#2196F3'
            };
        }
    }
}
</script>

<style lang="scss" scoped>
    .badge {
        border-radius: 10px;
        color: #fff;
        font-weight: 300;
        letter-spacing: 1px;
        padding: 4px 8px;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
    }
</style>