import Supabase from '@/supabaseInit';

export default {
    namespaced: true,
    state: () => ({
        authenticatedUser: null,
        roles: [],
        permissions: [],
    }),
    mutations: {
        setAuthenticatedUser(state, user) {
            state.authenticatedUser = user;

            // Copy the below lines under window.fcWidget.init inside fw_chat_widget function in the above snippet

            // To set unique user id in your system when it is available
            //window.fcWidget.setExternalId(user.id);

            // To set user name
            //window.fcWidget.user.setFirstName(user.name);

            // To set user email
            //window.fcWidget.user.setEmail(user.email);

        },
        setPermissions(state, permissions) {
            state.permissions = permissions;
        }
     },
    actions: {
        setAuthenticatedUser({commit}, data) {
            commit('setAuthenticatedUser', {
                id: data.user.id,
                name: data.user.user_metadata.full_name ?? data.user.user_metadata.display_name,
                email: data.user.email,
                image: data.user.user_metadata.avatar_url,
                token: data.access_token,
                user_metadata: data.user.user_metadata,
            });

            Supabase
                .from('users')
                .select('id,name,user_roles(roles(role_permissions(permissions(name))))')
                .eq('id', data.user.id)
                .then(({data}) => {
                    if (data.length > 0) {
                        data.user_roles = data[0].user_roles.map(
                            (user_role) => user_role.roles.role_permissions.map(
                                (permission) => permission.permissions.name
                            )
                        );
                        
                        commit('setPermissions', data.user_roles[0]);
                        return;
                    }
                    commit('setPermissions', []);
                });
        },
        async logout() {
            await Supabase.auth.signOut();
            location.reload();
        },
        async supabaseSignedOut({commit}) {
            commit('setAuthenticatedUser', null);
            commit('setPermissions', []);
        },
    },
    getters: {
        authenticatedUser(state) {
            return state.authenticatedUser;
        },
        can: (state) => (permission) => state.permissions?.includes(permission)
    }
}