<template lang="">
    <div id="reset-pwd-container">
        <img src="@/assets/img/ieadc-digital.png"/>
        <div class="reset-title">
            Vamos criar uma senha nova?
        </div>
        <div class="reset-form">
            <q-form @submit="resetPassword()">
                <q-input v-model="pwd" :rules="[required, minRule(6)]" type="password" label="Nova senha"/>
                <q-input v-model="pwd_confirm" :rules="[required, minRule(6), pwdMatch(this.pwd)]" type="password" label="Confirme a nova senha"/>
                <q-btn outline label="Trocar senha &raquo;" type="submit" style="margin-top: 24px;"/>
            </q-form>
        </div>
    </div>
</template>

<script>
import supabaseInit from '@/supabaseInit';
import { mapGetters } from 'vuex';
import ValidationMixin from '@/mixins/ValidationMixin';
import * as Yup from 'yup';

const pwdChangeSchema = Yup.object().shape({
  password: Yup.string().min(6).required(),
  password_confirm: Yup.string().min(6).oneOf([Yup.ref('password'), null], 'Passwords must match').required(),
});

    export default {
        name: 'ProfileView',
        mixins: [ValidationMixin],
        data() {
            return {
                pwd: null,
                pwd_confirm: null,
            }
        },
        methods: {
            async resetPassword() {
                try {
                    pwdChangeSchema.validateSync({
                        password: this.pwd,
                        password_confirm: this.pwd_confirm
                    });
                } catch (e) {
                    console.error(e)
                    return;
                }

                await supabaseInit.auth.updateUser({
                    password: this.pwd,
                })

                this.$router.push('/');
            }
        },
        computed: {
            ...mapGetters('auth', ['authenticatedUser']),
            fallbackProfilePic() {
                return "https://ui-avatars.com/api/?background=0D8ABC&color=fff&name="+this.authenticatedUser?.name;
            }
        }
    }
</script>

<style lang="scss" scoped>
    #reset-pwd-container {
        width: 100vw;
        height: 100vh;
        max-width: 700px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center ;
        text-align: center;
        gap: 24px;
    }
    
    .reset-title {
        font-size: 24px;
        letter-spacing: -1px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
    }

    .reset-explanation {
        font-size: 16px;
    }

    .reset-form {
        width: 80%;
    }

    img {
      display: inline;
      width: 33vw;
      max-width: 200px;
    }
</style>