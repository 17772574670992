<template lang="">
    <div id="fastpass-container">
        <h1>O que é o UMADC Fastpass? 🔥</h1>
        <span>
          O UMADC Fastpass é a forma fácil e prática de <b>reservar seu lugar</b> nas vigílias e eventos da UMADC.<br>
          Ou seja: você vai até nossos eventos com <b>menos filas e sem risco de ficar para fora.</b><br><br>
          E o melhor: tudo isso de forma <b>100% gratuita</b>! 💸<br>
          <br>
          <q-btn color="primary" @click="$router.push('/')" label="Ir para os eventos!" />
          <br>
          Atenção: lembramos que os eventos com Fastpass <b>continuam abertos para o público geral</b>, mesmo que sem inscrição via nossa plataforma.<br>O Fastpass garante acesso facilitado e lugar reservado dentro das regras abaixo.
        </span>
        <h1>Como funciona?</h1>
        <span>
          Para emitir seu Fastpass: 
          <ol>
            <li>
              Entrar na umadc.digital, ir até a página do evento
            </li>
            <li>
              Seguir as instruções na tela para gerar sua reserva gratuita
            </li>
            <li>
              Ao chegar no evento, basta apresentar o QR Code para a equipe de recepção (recomendamos tirar uma captura de tela do QR Code para evitar problemas com a conexão).
            </li>
            <li>
              Aproveite nosso evento!
            </li>
          </ol>
        </span>
        <h1>Tem mais alguma regra?</h1>
        <span>
          <ul>
            <li>
              O Fastpass é válido <b>até antes o início do evento</b>. Após o início, a reserva perde a validade.
            </li>
            <li>
              O Fastpass é <b>válido para apenas uma pessoa</b> e acompanhantes crianças. Cada participante acima dos 12 anos deverá emitir seu próprio Fastpass.
            </li>
            <li>
              Permitimos a emissão dos Fastpass até <b>uma hora antes</b> do horário programado do evento.
            </li>
          </ul>

          <h1>Preciso de ajuda!</h1>
          <ul>
            <li>
              Será um prazer ajudá-lo(a): envie um email para <b>suporte<span>@</span>umadc.digital</b>
            </li>
          </ul>

          <h1>Termos de uso</h1>
          <ul>
            <li>
              A UMADC se reserva ao direito unilateral de interromper, suspender ou cancelar o Fastpass em eventos, de acordo com a situação e capacidade operacional.
            </li>
            <li>
              Ao emitir o Fastpass, o emissor isenta a UMADC e a Assembleia de Deus em Curitiba de prejuízos morais e/ou financeiros decorrentes do mesmo.
            </li>
          </ul>
        </span>
    </div>
</template>
<script>
</script>
<style scoped lang="scss">
  #fastpass-container {
    padding: 30px;
  }

  h1 {
    color: #333;
    line-height: 30px;
    font-size: 28px;
    font-weight: 700;
  }
</style>