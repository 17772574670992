import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default {
    data() {
      return {
        q: null,
      }
    },  
  mounted() {
    /* eslint-disable */
      this.q = useQuasar();
      const $q = this.q;
    },
    methods: {
      async withLoader(fn) {
        this.q.loading.show({delay: 333});
        const response = await fn();
        this.q.loading.hide();
        return response;
      }
    },
}