<template lang="">
    <div class="app-menu">
        <ul>
            <li class="clickable" @click="navigateTo('/')">
                Próximos Eventos
            </li>
            <li class="clickable" @click="navigateTo('/all')">
                Todos os eventos
            </li>
            <li class="clickable" v-if="authenticatedUser" @click="navigateTo('/my-registrations')">
                Minhas inscrições
            </li>
            <li class="clickable" v-if="authenticatedUser && can('read_confirmations')" @click="navigateTo('/admin/registrations')">
                Todas as Inscrições
            </li>
            <li class="clickable" v-if="authenticatedUser && can('read_confirmations')" @click="navigateTo('/admin/single-page-signup')">
                Inscrição para terceiros
            </li>
            <li class="clickable" v-if="authenticatedUser && can('reports')" @click="navigateTo('/admin/reports')">
                Relatório
            </li>
            <li class="clickable" v-if="authenticatedUser && can('write_confirmations')" @click="navigateTo('/admin/pwd-reset')">
                Reset de Senha
            </li>
            <li class="clickable" v-if="authenticatedUser && can('checkin')" @click="navigateTo('/admin/checkin')">
                Checkin
            </li>
            <li class="clickable" v-if="! authenticatedUser" @click="navigateTo('/auth/login')">
                Fazer Login
            </li>
            <li class="clickable" v-if="authenticatedUser" @click="logout()">
                Sair
            </li>
        </ul>
        <div id="help">Precisa de ajuda?<br>Fale conosco em <b>suporte@umadc.digital</b></div>
        <div class="sl">
            by <strong style="font-weight: 900; letter-spacing: -1px;">Surreal<span class="l">Labs</span></strong>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'AppMenu',
    computed: {
        ...mapGetters('auth', ['authenticatedUser', 'can']),
    },
    methods: {
        navigateTo(to) {
            this.toggleMenu();
            setTimeout(()=>this.$router.push(to), 150)
        },
        ...mapActions(['hideOverlay', 'toggleMenu', 'logout']),
        ...mapActions('auth', ['logout']),
    }
}
</script>
<style lang="scss">
    .app-menu {
        color: white;

        font-weight: 300;
        ul {
            padding: 0px;
            list-style-type: none;
            margin: 16px 0px;
        }

        li {
            text-transform: uppercase;
            font-weight: 500;
            letter-spacing: 2px;
            padding: 3px;
        }

        #help {
            opacity: 0.75;
            line-height: 0.95 !important;
        }

        .sl {
            margin-top: 16px;
            opacity: 0.66;
            .l {
                opacity: .5;
            }
        }
    }
</style>