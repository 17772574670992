<template lang="">
    <div class="event-title" :class="{'extra-padding': extraPadding}">
        <span class="date">
            <span class="date--day">{{ day }}</span>
            <span class="date--month">{{ month }}</span>
        </span>
        <span class="description">
            <span class="description--title" :class="{'large': ! details}">{{ title }}</span>
            <span v-for="detail in details?.split(',')" :key="detail" class="description--detail">{{detail}}</span>
            <div class="badge-container" v-if="tags && tags.length > 0">
                <app-badge v-for="tag in tags" label-key="name" :color="tag.color" :key="tag" :value="tag"/>
            </div>
        </span>
    </div>
</template>
<script>
export default {
    name: "EventTitle",
    props: {
        startsAt: {
            type: [String],
            required: true,
        },
        title: {
            type: [String],
            required: true,
        },
        details: {
            type: [String],
            required: false,
        },
        extraPadding: {
            type: Boolean,
            default: false,
        },
        tags: {
            type: Array,
            default: () => ["Teste"],
        }
    },
    computed: {
        month() {
            return new Date(this.startsAt).toLocaleString('default', {'month': 'short'})
                .substring(0,3)
                .toUpperCase();
        },
        day() {
            return new Date(this.startsAt).getDate();
        }
    },
}
</script>
<style lang="scss" scoped>
.event-title {
    display: flex;
    flex-direction: row;
    text-align: left;
}

.extra-padding {
    padding: 4px;
}

.date {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-items: center;
    color: #666;
    
    &--day {
      font-size: 30px;
      line-height: 28px;
    }

    &--month {
      line-height: 12px;
    }
}

.description {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--title {
      font-weight: 700;
      display: block;
      line-height: 18px;
      margin-bottom: 4px;
      font-size: 18px;

      &.large {
        font-size: 24px;
        line-height: 24px;
      }
    }

    &--detail {
      font-size: 14px;
      line-height: 12px;
      font-weight: 300;
      display: block;
    }
}

.badge-container {
    display: flex;
    gap: 4px;
    margin-top: 10px;
}
</style>