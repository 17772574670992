<template lang="">
    <div id="animation-container">
        <span id="p1" :class="{'transp': revealStep < 1}">
            {{ name }},
        </span>
        <span id="p1" :class="{'transp': revealStep < 2}">
            falta só mais um detalhe...
        </span>
        <span id="p3"  :class="{'transp': revealStep < 3}">
            Precisamos <b>confirmar seu email</b>! <br><br> Enviamos um email de confirmação para você.<br> Confira sua caixa de entrada e clique no link.
            <br><br>
        </span>
        <span id="p4" :class="{'transp': revealStep < 4}">
            <i>Caso não ache, confira também a pasta de spam</i>
        </span>
    </div>
</template>
<script>

export default {
    data() {
        return {
            revealStep: 0,
        };
    },
    methods: {
    },
    mounted() {
        setTimeout(() => this.revealStep++, 1);
        setTimeout(() => this.revealStep++, 750);
        setTimeout(() => this.revealStep++, 2000);
        setTimeout(() => this.revealStep++, 3000);
    },
    computed: {
        name() {
            return this.$route.query.name;
        }
    },
}
</script>
<style lang="scss" scoped>
* {
    transition: 1s all;
}

#animation-container {
    height: 100vh;
    width: 100vw;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    padding: 30px;
}

span {
    text-align: left;
    background: linear-gradient(100deg, rgb(131, 255, 224) 0%, rgb(149, 202, 255) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#p1 {
    font-size: 24px;
    letter-spacing: -2px;
    font-weight: 700;
    line-height: 24px;
}

#p2 {
    position: relative;
    top: -12px;
    font-size: 42px;
    letter-spacing: -3px;
    font-weight: 700;
    line-height: 60px;
    background: linear-gradient(100deg, rgb(189, 255, 239) 0%, rgb(207, 231, 255) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#p3 {
    font-size: 16px;
    line-height: 18px;
    color: white;
    margin-top: 18px;
}

b {
    background: linear-gradient(100deg, rgb(189, 255, 239) 0%, rgb(207, 231, 255) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#btn {
    margin-top: 30px;
    width: 100%;
}

.transp {
    opacity: 0 !important;
}</style>