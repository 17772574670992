<template lang="">
    <div>
        <div class="margin: 8px;">
            <q-select :options="reallyGetAllEvents"
                v-model="event"
                name="status"
                behavior="menu"
                option-value="id"
                option-label="title"
                style="width: 100%; padding: 0px 16px; padding-top: 32px;"
                label="Evento"/>
        </div>
        <div id="scanner-container">
            <q-btn label="Iniciar Scanner" @click="start" :disable="scanning"/>&nbsp;
            <q-btn label="Parar Scanner" @click="stop"  :disable="! scanning"/>
        </div>
        <video ref="view"></video>
        <div id="scanner-container">
            <q-form @submit="handleCodeCheckin()">
                <q-input v-model="code" :disable="! event" :mask="mask" :placeholder="event ? 'Selecione um evento' : 'CPF ou Código Manual'"/>
                <q-btn type="submit" :disable="! event">Realizar Checkin via Código</q-btn>
            </q-form>
        </div>
    </div>
</template>
<script>
import NotifyMixin from '@/mixins/NotifyMixin';
import QrScanner from 'qr-scanner';
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
    name: 'CheckinScannerView',
    mixins: [NotifyMixin],
    data() {
        return {
            code: null,
            scanner: null,
            interval: null,
            scanning: false,
            latestCheckin: null,
            event: null,
        }
    },
    methods: {
        start() {
            if (! this.scanner) {
                this.scanner = new QrScanner(
                    this.$refs.view,
                    result => this.handleQrCode(result),
                    { maxScansPerSecond: 10, highlightScanRegion: true },
                );
            }
            this.scanning = true;
            setTimeout(() => this.scanner.start(), 1);
            this.interval = setInterval(
                () => {
                    this.scanner.stop();
                    setTimeout(() => this.scanner.start(), 1000);
                }
            , 30000);
        },
        stop() {
            this.scanner.destroy();
            this.scanner = null;
            this.scanning = false;
            clearTimeout(this.interval);
        },
        checkinSuccess(name) {
            setTimeout(() => this.$q.notify({
                type: 'positive',
                position: 'top',
                html: true,
                progress: true,
                timeout: 3000,
                message: `<big>Checkin de ${name.split(" ")[0]} feito!</big>`,
            }), 1000);
            
        },
        async handleQrCode(result) {
            if (this.latestCheckin == result.data) {
                return;
            }

            this.latestCheckin = result.data;
            await axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/v1/admin/checkin`,
                {'scanned': result.data},
                { headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'application/json' } }
            ).then(({data: {users: { name }}}) => this.checkinSuccess(name));
        },
        async handleCodeCheckin() {
            await axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/v1/admin/checkin`,
                {'manual': this.code, 'eventId': this.event.id},
                { headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'application/json' } }
                ).then(({data: {users: { name }}}) => this.checkinSuccess(name));
        },
    },
    async unmounted() {
        this.stop();
    },
    async mounted() {
        this.scanner = new QrScanner(
            this.$refs.view,
            result => this.handleQrCode(result),
            { maxScansPerSecond: 10, highlightScanRegion: true },
        );
    },
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
        ...mapGetters('events', ['reallyGetAllEvents']),
        mask() {
            return this.code && this.code.length > 6 ? '###.###.###-##' : '#######';
        }
    }
}
</script>
<style lang="scss" scoped>
    video {
        width: 100%;
        //border-radius: 30px;
        background-color: #eee;
    }

    #scanner-container {
        padding: 30px;
    }
</style>