import axios from 'axios';

export default {
    namespaced: true,
    state: () => ({
        reportsData: [],
        myTemples: [],
        atendeesPerLocation: [],
        eventId: -1,
    }),
    mutations: {
        setReportsData(state, reportsData) {
            state.reportsData = reportsData;
        },
        setMyTemples(state, myTemples) {
            state.myTemples = myTemples;
        },
        setAtendeesPerLocation(state, atendeesPerLocation) {
            state.atendeesPerLocation = atendeesPerLocation;
        },
        setEventId(state, eventId) {
            state.eventId = eventId;
        },
    },
    /* eslint-disable */
    actions: {
        async queryReportsData({commit, rootState, state}) {
            const supabaseConfirmations = await axios.get(
                `${process.env.VUE_APP_BACKEND_URL}/v1/admin/reports?event=${state.eventId}`,
                { 
                    headers: { Authorization: `Bearer ${rootState.auth.authenticatedUser.token}`, 'Content-Type': 'application/json' }
                }
            );

            commit('setReportsData', supabaseConfirmations.data);
        },
        async queryMyTemples({commit, rootState, state}) {
            const myTemplesResponse = await axios.get(
                `${process.env.VUE_APP_BACKEND_URL}/v1/admin/reports/my-locations?event=${state.eventId}`,
                { 
                    headers: { Authorization: `Bearer ${rootState.auth.authenticatedUser.token}`, 'Content-Type': 'application/json' }
                }
            );

            commit('setMyTemples', myTemplesResponse.data);
        },
        async queryAtendeesPerLocation({commit, rootState, state}, atendeesPerLocation) {
            const atendeesPerLocationResponse = await axios.get(
                `${process.env.VUE_APP_BACKEND_URL}/v1/admin/reports/atendees-per-location/${atendeesPerLocation}?event=${state.eventId}`,
                { 
                    headers: { Authorization: `Bearer ${rootState.auth.authenticatedUser.token}`, 'Content-Type': 'application/json' }
                }
            );

            commit('setAtendeesPerLocation', atendeesPerLocationResponse.data);
        },
        async setEventId({commit}, eventId) {
            commit('setEventId', eventId);
        }
    },
    getters: {
        reportsData(state) {
            return state.reportsData;
        },
        myTemples(state) {
            return state.myTemples;
        },
        atendeesPerLocation(state) {
            return state.atendeesPerLocation;
        },
        getEventId(state) {
            return state.eventId;
        }
    }
}