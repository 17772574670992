import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions('admconfirmations', ['updateConfirmationStatus']),
        approveConfirmation(id) {
            this.updateConfirmationStatus({ id, status: 'paid' });
        },
        denyConfirmation(id) {
            this.q.dialog({
                title: 'Razão da recusa',
                message: 'Qual é a razão da recusa? (irá aparecer para o inscrito)',
                prompt: {
                    model: '',
                    isValid: val => val.length > 2, // << here is the magic
                    type: 'text' // optional
                },
                cancel: true,
                persistent: true
            }).onOk(data => {
                this.updateConfirmationStatus({ id, status: 'rejected', reason: data });
            })
        },
    },
}