import Supabase from '@/supabaseInit';
export default {
    namespaced: true,
    state: () => ({
        allTags: [],
    }),
    mutations: {
        setAllTags(state, tags) {
            state.allTags = tags;
        },
        pushTag(state, tag) {
            state.allTags.push(tag);
        },
    },
    actions: {
        async queryAllTags({commit}) {
            Supabase.from('tags')
                .select()
                .is('deleted_at', null)
                .then(({data}) => commit('setAllTags', data));
        },
        pushTag({commit}, tag) {
            commit('pushTag', tag);
        },
    },
    getters: {
        getAllTags(state) {
            return state.allTags;
        },
    }
}