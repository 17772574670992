<template lang="">
    <div class="registration-detail-container" v-if="updatedRegistration">
        <h1>Inscrição para {{ updatedRegistration.pricing_tiers.events.title }}</h1>
        <price-tier :tier="updatedRegistration.pricing_tiers" :ignore-expiration="true" :hide-cta="true" />
        <div v-for="(confirmation, key) in sortedConfirmations" :key="confirmation.id">
            <h2>Tentativa {{updatedRegistration.registration_confirmations.length - key}} em {{ formatDate(confirmation.created_at) }}</h2>
            <registration-confirmation :confirmation="confirmation" />
            <hr/>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import PriceTier from '@/components/PriceTier.vue';
import RegistrationConfirmation from '@/components/PaymentDetail/RegistrationConfirmation.vue';
// import Supabase from '@/supabaseInit';
import moment from 'moment';

export default {
    name: 'RegistrationDetails',
    props: ['registration'],
    components: { PriceTier, RegistrationConfirmation },
    data() {
        return {
            qrUrl: null,
            // listener: null,
        }
    },
    methods: {
        ...mapActions('registrations', ['updateSingleRegistration']),
        formatDate(date) {
            return (new Date(date)).toLocaleDateString('pt-BR', {day: '2-digit', month: '2-digit', hour: '2-digit', minute: '2-digit'});
        }
    },
    async mounted() {
        this.updateSingleRegistration(this.registration.id);
        /*this.listener = Supabase
            .channel('schema-db-changes')
            .on(
                'postgres_changes',
                {
                    event: 'UPDATE',
                    schema: 'public',
                },
                () => {this.updateSingleRegistration(this.registration.id); }
            )
            .subscribe();*/
    },
    computed: {
        updatedRegistration() {
            return this.$store.getters['registrations/getSingleRegistration'](this.registration.id);
        },
        sortedConfirmations() {
            const unsorted = this.updatedRegistration.registration_confirmations;
            return unsorted.sort((registrationA, registrationB) => moment(registrationB.created_at) - moment(registrationA.created_at))
        }
    },
    async unmounted() {
        // await this.listener.unsubscribe();
    },
}
</script>
<style lang="scss" scoped>
    h1 {
        color: #333;
        line-height: 30px;
        font-size: 28px;
        font-weight: 700;
        text-align: left;
        padding: 0;
        margin: 0;
    }

    h2 {
        font-weight: 300;
        color: #666;
        margin: 0;
        padding: 0;
        font-size: 24px;
        text-align: left;
    }

    .registration-detail-container {
        padding: 30px 15px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-x: scroll;
        height: 100%;
    }
</style>