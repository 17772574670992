<template>
    <div style="display: flex; justify-content: center; width:100vw;">
        <div style="margin: 0; width: 100%; max-width: 700px; text-align: center;">
            <router-view/>
            <app-overlay />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AppOverlay from '@/components/AppOverlay.vue'
import { useQuasar } from 'quasar'

export default {
  name: 'FullScreenLayout',
  components: {AppOverlay},
  methods: {
    ...mapActions(['hideOverlay'])
  },
  computed: {
    ...mapGetters(['isOverlayVisible', 'getOverlay', 'isMenuVisible'])
  },
  mounted() {
    const $q = useQuasar()
    $q.dark.set(true)
  },
}
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;500;700;900&display=swap');
</style>
