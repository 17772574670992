<template lang="">
    <div class="app-overlay-bg" :class="{'visible': isOverlayVisible}" @click="hideOverlay()">
    </div>
    <div class="app-overlay" :class="{'visible': isOverlayVisible}">
        <component
            :is="overlay?.type"
            v-bind="overlay?.props"
            v-on="overlay?.events" />
    </div>
</template>
<script>
import EventBody from '@/components/EventBody.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: "AppOverlay",
    components: {EventBody},
    methods: {
        ...mapActions(['hideOverlay'])
    },
    computed: {
        ...mapGetters(['isOverlayVisible', 'overlay'])
    },
}
</script>
<style lang="scss" scoped>
.app-overlay {
    height: calc(100vh - 70px);
    width: 100%;
    max-width: 700px;
    position: fixed;
    top: 100vh;
    transition: top 0.5s;
    background-color: white;
    border-radius: 60px 60px 0px 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    overflow: hidden;
    &.visible {
        top:70px;
    }
}

.app-overlay-bg {
    transition: all 0.5s;
    &.visible {
        background-color: rgba(0,0,0,0.5);
        pointer-events: all;
    }
    pointer-events: none;
    background-color: rgba(0,0,0,0.0);
    position: fixed;
    top: 0; left: 0;
    height: 100vh;
    width: 100vw;
}
</style>