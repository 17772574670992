<template lang="">
    <div>
        <img v-if="qrUrl" :src="qrUrl" />
        <code v-if="code">Código para uso manual: <b>{{ registration?.seq }}</b></code>
    </div>
</template>
<script>
import QRCode from 'qrcode'
import Supabase from '@/supabaseInit';
import { mapActions } from 'vuex';
export default {
    'name': 'OpenDetails',
    props: ['confirmation', 'registration'],
    data() {
        return {
            qrUrl: null,
            code: true,
            listener: null,
        };
    },
    async beforeUnmount() {
        await this.listener.unsubscribe();
    },
    async mounted() {
        this.listener = Supabase
            .channel('schema-db-changes')
            .on(
                'postgres_changes',
                {
                    event: 'INSERT',
                    schema: 'public',
                },
                (data) => this.handleMessage(data)
            )
            .subscribe();
    },
    methods: {
        ...mapActions(['hideOverlay']),
        async handleMessage(message) {
            if (message.table == 'checkins' && message.eventType == 'INSERT') {
                await this.listener.unsubscribe();
                this.listener == null;
                this.$router.push('/event-welcome');
                this.hideOverlay();
            }
        }
    },
    watch: {
        async registration() {
            if (this.registration?.seq) {
                this.qrUrl = await QRCode.toDataURL(`checkin:${this.registration?.id}`, { errorCorrectionLevel: 'L' });
            }
        }
    }
}
</script>
<style scoped lang="scss">
    img {
        width: 100%;
    }
</style>