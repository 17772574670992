import Supabase from '@/supabaseInit';
import moment from 'moment';
export default {
    namespaced: true,
    state: () => ({
        allRegistrations: [],
    }),
    mutations: {
        setAllRegistrations(state, registrations) {
            state.allRegistrations = registrations;
        },
        pushRegistrations(state, registrations) {
            const tempRegistrations = state.allRegistrations;
            state.allRegistrations = [];
            registrations.forEach((registration) => {
                tempRegistrations[registration.id] ?? (tempRegistrations[registration.id] = registration);
            });
            state.allRegistrations = tempRegistrations;
        },
        updateSingleRegistration(state, updatedRegistration) {
            state.allRegistrations[updatedRegistration.id] = updatedRegistration;
        }
    },
    actions: {
        async queryRegistrations({commit, rootState}) {
            if (! rootState?.auth?.authenticatedUser?.id) {
                commit('pushRegistrations', []);
                return;
            }

            let supabaseRegistrations = await Supabase.from('registrations')  // aquiaqui
                .select('id,seq,pricing_tiers(id,name,price,events(id,title,starts_at)),frozen_value,status,registration_confirmations(id, strategy)')
                .eq('user_id', rootState.auth.authenticatedUser.id);

            commit('pushRegistrations', supabaseRegistrations.data);
        },
        async updateSingleRegistration({commit}, id) {
            let updatedRegistration = await Supabase
                .from('registrations')
                .select('id,seq,pricing_tiers(id,name,price,custom_pix_key,payment_link,events(id,title,starts_at)),frozen_value,status,registration_confirmations(id,strategy,created_at,registration_id,request_data,response_data,transaction_id,status,expires_at)')
                .eq('id', id)
                .limit(1);

            commit('updateSingleRegistration', updatedRegistration.data[0]);
        },
    },
    getters: {
        getAllRegistrations(state) {
            return Object.values(state.allRegistrations).sort((registrationA, registrationB) => moment(registrationA.starts_at) - moment(registrationB.starts_at));
        },
        getSingleRegistration: (state) => (registrationId) => {
            return state.allRegistrations[registrationId];
        },
        getEventRegistration: (state) => (eventId) => {
            return Object.values(state.allRegistrations).find((registration) => registration.pricing_tiers.events.id == eventId);
        },
    }
}