export default {
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/authenticatedUser'] != null;
    }
  },
  methods: {
    can(permission) {
      return this.$store.getters['auth/can'](permission);
    }
  }
}