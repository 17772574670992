<template lang="">
    <div id="animation-container">
        <span id="p1" v-if="authenticatedUser?.name" :class="{'transp': revealStep < 1}">
            {{(authenticatedUser?.name).split(" ")[0] }},
        </span>
        <span id="p2" :class="{'transp': revealStep < 2}">
            {{ message() }}
        </span>
        <span id="p3" :class="{'transp': revealStep < 3}">
            Seja bem-vindo(a) a nosso evento!
        </span>
        <span id="p4" :class="{'transp': revealStep < 4}">
            Ah! Se você for postar stories, marca a gente!<br>
            @umadccuritibaoficial
        </span>
        <q-btn @click="goToEvent" outline :class="{'transp': revealStep < 4}" style="margin-top: 16px;">IR PARA EVENTO &raquo;</q-btn>
    </div>
</template>
<script>
import ValidationMixin from '@/mixins/ValidationMixin';
import { mapGetters } from 'vuex';

export default {
    mixins: [ValidationMixin],
    data() {
        return {
            revealStep: 0,
            messageIdx: null,
        };
    },
    methods: {
        message() {
            const msgs = [
                'Prepare-se para viver algo novo!',
                'É bom demais ter você conosco!',
                'Um tempo de renovação está chegando!',
                'Prepare-se para um tempo de intimidade com o Pai!',
                'Você já pode sentir a presença dEle aqui!',
                'É dia de aprofundar na presença dEle!',
                'Hoje é dia de novas experiências com Ele!',
                'Ele tem algo especial para você aqui!',
                'Aproveite da paz que só ele pode oferecer!',
                'Deus está há uma oração de você!',
                'Sinta o mover do Espírito neste local hoje!',
                'Prepare-se para ter sua fé renovada hoje!',
                'É dia de ser fortalecido no amor dEle!',
                'Um encontro único com Ele te aguarda!',
                'Você já pode sentir a Glória dele aqui!',
                'Hoje é dia de um novo nível com o Pai!',
                'É dia de colocar a fé em prática!',
                'Ele te diz: te amo, filho(a) meu!',
                'Ele te diz: nem uma única promessa falhará!',
                'Ele te diz: Eu estou do seu lado, todos os dias!',
                'Ele te diz: confie em Mim! Eu estou no controle!',
                'Ele te diz: você é único e especial para mim!',
                'Ele te diz: Sou o refúgio seguro no meio da adversidade!',
                'Ele te diz: Eu completarei a boa obra em ti!',
                'Ele te diz: não se esqueça, Eu venci o mundo!',
                'Ele te diz: a minha força está em você.',
                'Ele te diz: nas suas fraquezas, a minha graça se manifesta de forma poderosa.',
                'Ele te diz: As suas lágrimas não são em vão; Eu as enxugarei e trarei alegria ao seu coração.',
                'Ele te diz: Nada pode separá-lo(a) do meu amor incondicional.',
                'Ele te diz: Quando você sentir que não tem mais forças, lembre-se de que Eu sou a sua fortaleza.',
                'Ele te diz: As suas batalhas não são suas, são minhas; e Eu lutarei por você.',
                'Ele te diz: Seu passado não define o seu futuro; comigo, há sempre uma nova chance.',
            ];
            return msgs[this.messageIdx];
        },
        goToEvent() {
            window.location.href = '/';
        }
    },
    async mounted() {
        this.messageIdx = Math.ceil(Math.random()*31);
        setTimeout(() => this.revealStep++, 1);
        setTimeout(() => this.revealStep++, 500);
        setTimeout(() => this.revealStep++, 1500);
        setTimeout(() => this.revealStep++, 2500);
    },
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
    },
}
</script>
<style lang="scss" scoped>
* {
    transition: 1s all;
}

#animation-container {
    height: 100vh;
    width: 100vw;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    padding: 30px;
}

span {
    text-align: left;
    background: linear-gradient(100deg, rgb(131, 255, 224) 0%, rgb(149, 202, 255) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#p1 {
    font-size: 24px;
    letter-spacing: -2px;
    font-weight: 700;
    line-height: 24px;
}

#p2 {
    position: relative;
    top: 0px;
    font-size: 38px;
    letter-spacing: -3px;
    font-weight: 700;
    line-height: 40px;
    background: linear-gradient(100deg, rgb(189, 255, 239) 0%, rgb(207, 231, 255) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#p3 {
    margin-top: 32px;
    font-size: 24px;
    letter-spacing: -2px;
    font-weight: 700;
    line-height: 24px;
}

#p4 {
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 500;
    line-height: 18px;
}

b {
    background: linear-gradient(100deg, rgb(189, 255, 239) 0%, rgb(207, 231, 255) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#btn {
    margin-top: 30px;
    width: 100%;
}

.transp {
    opacity: 0 !important;
}</style>