<template lang="">
    <div class="registrations-container">
        <h1>Minhas inscrições</h1>
        <div v-if="paidInFuture.length == 0 && pendingInFuture.length == 0 && inPast.length == 0" class="text-grey text-lighten-2">
            Você ainda não tem nenhuma inscrição... que tal participar de um dos nossos eventos?!
        </div>
        <div class="card-container" v-if="paidInFuture.length > 0">
            <h2>Inscrições ativas</h2>
            <registration-list-item v-for="registration in paidInFuture" :key="registration.id" :registration="registration" />
        </div>
        <div class="card-container" v-if="pendingInFuture.length > 0">
            <h2>Pagamento pendente/análise</h2>
            <registration-list-item v-for="registration in pendingInFuture" :key="registration.id" :registration="registration" />
        </div>
        <div class="card-container" v-if="inPast.length > 0">
            <h2>Inscrições passadas</h2>
            <registration-list-item v-for="registration in inPast" :key="registration.id" :registration="registration" />
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import RegistrationListItem from '@/components/RegistrationListItem.vue';
import moment from 'moment';
import RegistrationDetails from './RegistrationDetails.vue';

export default {
    name: 'MyRegistrationsView',
    components: {RegistrationListItem},
    mounted() {
      this.queryRegistrations();
      if (this.$route.params.registrationId) {
        this.showOverlay({'component': RegistrationDetails, props: {'registration': {id: this.$route.params.registrationId}}, events: []});
      }
    },
    methods: {
        ...mapActions('registrations', ['queryRegistrations']),
        ...mapActions(['showOverlay']),

    },
    computed: {
        allRegistrations() {
            return this.$store.getters['registrations/getAllRegistrations']
        },
        pendingInFuture() {
            return this.$store.getters['registrations/getAllRegistrations']
                .filter((reg)=>{
                    return reg.status != 'paid' && moment(reg.pricing_tiers.events.starts_at) > moment.now();
                });
        },
        paidInFuture() {
            return this.$store.getters['registrations/getAllRegistrations']
                .filter((reg)=>{
                    return reg.status == 'paid' && moment(reg.pricing_tiers.events.starts_at) > moment.now();
                });
        },
        inPast() {
            return this.$store.getters['registrations/getAllRegistrations']
                .filter((reg)=>{
                    return moment(reg.pricing_tiers.events.starts_at) < moment.now();
                });
        },
    }
}
</script>
<style lang="scss" scoped>
h1 {
    color: #333;
    line-height: 30px;
    font-size: 28px;
    font-weight: 700;
    text-align: left;
    padding: 0;
    margin: 0;
}

h2 {
    font-weight: 300;
    color: #666;
    margin: 0;
    padding: 16px 0px;
    font-size: 20px;
    line-height: 26px;
  }

.registrations-container {
    padding: 30px;
}

.card-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
</style>