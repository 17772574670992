<template lang="">
    <div>
        <div style="padding: 10px;">
            <b>Nome:</b> {{confirmation.registrations.users.name}}<br>
            <b>Contato:</b> {{confirmation.registrations.users.email}} - {{confirmation.registrations.users.phone}}<br>
            <b>Método de pagamento: </b> {{ humanPaymentMethod }}
        </div>
        <component :is="componentType" :confirmation="confirmation" />
    </div>
</template>

<script>
    import AdminCashConfirmationDetail from './AdminCashConfirmationDetail.vue';
    import AdminFileConfirmationDetail from './AdminFileConfirmationDetail.vue';
    import AdminOnlineConfirmationDetail from './AdminOnlineConfirmationDetail.vue';
    import AdminVoucherConfirmationDetail from './AdminVoucherConfirmationDetail.vue';

    export default {
        name: 'AdminConfirmationDetail',
        props: ['confirmation'],
        computed: {
            componentType() {
                switch(this.confirmation.strategy) {
                    case 'cash':
                    case 'link':
                        return AdminCashConfirmationDetail;
                    case 'file':
                        return AdminFileConfirmationDetail;
                    case 'mp_pix':
                    case 'mp_card':
                        return AdminOnlineConfirmationDetail;
                    case 'voucher':
                        return AdminVoucherConfirmationDetail
                }
                return 'aaa';
            },
            humanPaymentMethod() {
                switch(this.confirmation.strategy) {
                    case 'mp_card':
                        return 'Cartão de crédito';
                    case 'mp_pix':
                        return 'PIX';
                    case 'file':
                        return 'Comprovante PIX';
                    case 'cash':
                        return 'Pagamento presencial';
                    case 'voucher':
                        return 'Voucher';
                    case 'link':
                        return 'Pagseguro';
                    case 'open':
                        return 'Gratuito';
                    default:
                        return "PM";
                }
            }
        }
    }
</script>

<style lang="">
    
</style>