<template lang="">
    <div>
        <q-stepper
                v-model="step"
                vertical
                flat
                color="primary"
                animated
                style="text-align: left;"
                >
                <q-step
                    :name="1"
                    title="Como funciona?"
                    :done="step > 0"
                >
                    O pagamento será realizado através de um voucher.<br>
                    Insira no campo de texto abaixo o voucher.<br><br>

                    <q-input large :loading="isTesting" placeholder="Insira aqui o voucher" :disable="isTesting" mask="XXXX-XXXX" unmasked-value :rules="[required, exactLength(8)]" v-model="voucher" style="font-size: 24px" />

                    <div style="margin-top: 16px;" v-if="confirmation.status == 'pending'">
                        <small>Para trocar o método de pagamento, cancele a inscrição atual e inicie uma nova.</small><br>
                        <q-btn color="red" @click="$emit('switch-method')">CANCELAR INSCRIÇÃO</q-btn>
                    </div>
                </q-step>
                <q-step
                    :name="2"
                    title="Resultado"
                    :done="step > 1"
                >
                    <div v-if="confirmation.status == 'rejected'">
                        Voucher inválido. Inicie outra inscrição para tentar novamente.
                    </div>
                    <div v-else>
                        Voucher <b>aceito</b>! Sua inscrição foi confirmada.
                    </div>
                </q-step>
        </q-stepper>
    </div>
</template>
<script>
import ValidationMixin from '@/mixins/ValidationMixin';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';

export default {
    mixins: [ValidationMixin],
    'name': 'VoucherDetails',
    props: ['confirmation', 'registration'],
    data() {
        return {
            voucher: null,
            isTesting: false,
        }
    },
    methods: {
        ...mapActions('registrations', ['updateSingleRegistration']),
        async testVoucher() {
            this.isTesting = true;
            axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/v1/confirmation/${this.confirmation.id}/voucher`,
                {
                    "voucher": this.voucher.toLowerCase(),
                    "eventId": this.registration.pricing_tiers.events.id,
                    "tierId": this.registration.pricing_tiers.id,
                },
                { headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'application/json' } }
            )
                .then(() => {
                    this.$q.notify({
                        message: "Inscrição confirmada com sucesso!",
                        color: 'positive',
                        position: 'bottom',
                        timeout: 5000,
                    });
                    setTimeout(() => {
                        this.$router.push('/my-registrations');
                    }, 2000);
                }).catch(({response: { data: { error } }}) => {
                    this.isTesting = false;
                    this.$q.notify({
                        message: error,
                        color: 'negative',
                        position: 'bottom',
                        timeout: 5000,
                    });
                });
        }
    },
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
        step() {
            switch(this.confirmation.status) {
                case 'pending':
                    return 1;
                case 'rejected':
                case 'paid':
                    return 2;
            }
            return 1;
        }
    },
    watch: {
        voucher(newVal) {
            setTimeout(() => {
                if (newVal && newVal.length == 8) {
                    this.testVoucher();
                }
            }, 200);
        }
    }
}
</script>
<style lang="">
    
</style>