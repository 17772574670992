<template lang="">
    <div class="speakers-form">
        <div class="title-1">
            {{ formTitle }}
        </div>
        <q-form @submit="save">
            <q-input :rules=[required] v-model="speaker.name" label="Nome"/>
            <q-input v-model="speaker.details" label="Detalhes"/>

            <input @change="newImageChanged($event)" id="fileInput" type="file"/>
            <img v-if="! showCropper" style="width: 100%;" :src="bgImage" />

            <cropper
                class="cropper"
                :src="bgImage"
                :stencil-component="$options.components.CircleStencil"
                ref="cropper"
                v-if="showCropper"
            />

            <div class="action-buttons-container">
                <q-btn class="primary" @click="cancel">Cancelar</q-btn>
                <q-btn class="primary" type="click" color="primary">Salvar</q-btn>
            </div>
        </q-form>
    </div>
</template>
<script>
import Supabase from "@/supabaseInit";
import { mapActions } from 'vuex';
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import ValidationMixin from '@/mixins/ValidationMixin';
import * as Yup from 'yup';
import UploadMixin from "@/mixins/UploadMixin";

const speakerSchema = Yup.object().shape({
    name: Yup.string().required(),
});

export default {
    components: {
		Cropper,
        // eslint-disable-next-line
        CircleStencil,
	},
    props: ['model'],
    mixins: [ValidationMixin, UploadMixin],
    mounted() {
        if (this.model)
            this.speaker = this.model;
    },
    data() {
        return {
            speaker: {
                id: null,
                name: null,
                details: null,
                image: null,
            },
            showCropper: false,
            newImage: null,
        }
    },
    methods: {
        ...mapActions(['hideOverlay']),
        ...mapActions('speakers', ['pushSpeaker']),
        async save() {
            const data = {
                name: this.speaker.name,
                details: this.speaker.details,
                image: this.speaker.image,
            };

            try {
                speakerSchema.validateSync(data);
            } catch (e) {
                console.error(e);
                return;
            }

            if (this.showCropper) {
                await this.crop();
            }

            if (this.newImage) {
                //const format = this.newImage.name.split('.').slice(-1);

                if (this.speaker.image) {
                    await this.deleteFile('speakerImage', this.speaker.image)
                }

                const { path } = await this.uploadFile('speakerImage', this.newImage);
                
                data.image = path;
            }
            
            if (this.speaker.id)
                data.id = this.speaker.id;
            
            const newSpeaker = await Supabase.from('speakers').upsert(data).select();

            if (! data.id) {
                this.pushSpeaker(newSpeaker.data[0]);
                this.$emit('selected', newSpeaker.data[0]);
            }

            this.hideOverlay();
        },
        cancel() {
            this.hideOverlay();
        },
        newImageChanged(event) {
            if (! event) return;

            this.newImage = event.target.files[0];
            this.speaker.image = URL.createObjectURL(this.newImage);
            this.showCropper = true;
        },
        crop() {
            const { canvas } = this.$refs.cropper.getResult();
            return new Promise((resolve) => {
                canvas.toBlob((blob) => {
                    this.speaker.image = URL.createObjectURL(blob);
                    this.showCropper = false;
                    this.newImage = new File([blob], "filename");
                    resolve();
                });
            });
			
        }
    },
    computed: {
        formTitle() {
            return this.speaker.id == null ? 'Novo participante' : 'Editar participante';
        },
        bgImage() {
            if (! this.speaker.image)
                return null;

            if (this.speaker.image.startsWith('blob'))
                return this.speaker.image;

            return `https://storage.googleapis.com/umadc-digital-public/${this.speaker.image}`;
        },
    },
}
</script>
<style lang="scss" scoped>
    .speakers-form {
        padding: 30px;
        text-align: left;
    }

    .title-1 {
        color: #333;
        line-height: 30px;
        font-size: 28px;
        font-weight: 700;
    }

    .action-buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 16px 0px;
    }
</style>