<template lang="">
    <div>
        <div class="header" :style="headerStyle">
            <span class="title">{{ updatedEvent?.title }}</span>
            <!-- <span v-if="updatedEvent.speakers" class="speakers">{{stringStartDate}}</span> -->
        </div>
        <div id="event-view-container">
            <div>
                <h2>Check-in</h2>
                <span v-if="showQr">
                    <img v-if="qrUrl" :src="qrUrl" style="width: 66vw; max-width: 600px;" /><br>
                    <br>
                </span>
                <q-btn color="white text-black" :label="! showQr?'Fazer checkin':'Pronto!'" @click="showQr = ! showQr"/>
            </div>
            <div v-if="event?.child_events?.length > 0">
                <h2>Sub-eventos</h2>
                <card
                    v-for="childEvent in event?.child_events"
                    :key="childEvent.id"
                    @click="joinEvent(childEvent)"
                    :image="childEvent.image">
                        <template v-slot:body>
                            <EventTitle
                            :starts-at="childEvent.starts_at"
                            :title="childEvent.title"
                            :tags="childEvent.tags"
                            :details="childEvent.details"/>
                        </template>
                </card>
            </div>
            <div v-if="event?.files?.length > 0" style="padding: 16px 0px;">
                <h2>Arquivos</h2>
                <EventFilesComponent v-if="event" :files="event.files" />
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import EventFilesComponent from '@/components/events/EventFilesComponent.vue';
import Card from '@/components/Card.vue';
import EventTitle from "@/components/EventTitle.vue"
import EventJoinerMixin from '@/mixins/EventJoinerMixin';
import NotifyMixin from '@/mixins/NotifyMixin';
import QRCode from 'qrcode'
export default {
    components: {EventFilesComponent, Card, EventTitle},
    mixins: [EventJoinerMixin, NotifyMixin],
    data() {
        return {
            id: null,
            event: null,
            showQr: false,
            qrUrl: null,
        }
    },
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
        updatedEvent() {
            return this.$store.getters['events/getSingleEvent'](this.id);
        },
        updatedRegistration() {
            return this.$store.getters['registrations/getEventRegistration'](this.id);
        },
        headerStyle() {
            return `background-image: linear-gradient(0deg, rgba(0, 50, 100, 0.7), rgba(0, 50, 100, 0.7)) ,url('https://storage.googleapis.com/umadc-digital-public/${this.updatedEvent?.image}')`;
        },
    },
    methods: {
        ...mapActions('registrations', ['queryRegistrations']),
        async updateEvent() {
            this.id = this.$route.params.id;
            if (! this.id) return;
            const { data } = await axios.get(
                `${process.env.VUE_APP_BACKEND_URL}/v1/events/${this.id}`,
                {
                    headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'application/json' }
                }
            );

            this.event = data;
        }
    },
    async mounted() {
        await this.queryRegistrations();
        this.qrUrl = await QRCode.toDataURL('checkin:' + this.updatedRegistration?.id, { errorCorrectionLevel: 'L' });
    },
    watch: {
        "$route.params.id": { 
            handler () {
                this.event = {};
                this.updateEvent();
            },
            immediate: true // will call handler on component mount / create as well, so no need to call fn on mounted again
        },
        async registration() {
            if (this.registration?.seq) {
                this.qrUrl = await QRCode.toDataURL(`checkin:${this.registration?.id}`, { errorCorrectionLevel: 'L' });
            }
        }
    },
}
</script>
<style lang="scss" scoped>

.event-body {
    overflow-x: scroll;
    height: 100%;

    font-size: 14px;
}
.header {
    border-radius: 60px 60px 0px 0px;
      background-size: cover;
      background-position: center;
      height: 200px;
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      text-align: left;
      padding: 24px;

      .title {
        font-weight: 700;
        font-size: 24px;
        letter-spacing: -1px;
        line-height: 28px;
      }

      .speakers {
        font-weight: 300;
        font-size: 12px;
        letter-spacing: -0.5px;
        line-height: 16px;
        
      }

      &:before {
        background-color: #000;
        width: 50%;
      }
    }

    #event-view-container {
        padding: 30px;
    }

h2 {
    font-weight: 300;
    color: #666;
    margin: 0;
    font-size: 24px;
    line-height: 28px;
}
    // https://storage.googleapis.com/umadc-digital-public/${this.updatedEvent?.image}
</style>