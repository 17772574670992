<template lang="">
    <div>
        <div style="padding: 10px;">
            <b>Envio do comprovante: </b> 
            <q-badge v-if="confirmation.signedUrl" rounded label="Enviado" color="success" style="padding: 4px 8px;" />
            <q-badge v-if="!confirmation.signedUrl" rounded label="Pendente" color="orange" style="padding: 4px 8px;" /><br>
            <a :href="confirmation.signedUrl" target="_blank">Abrir arquivo em nova aba</a>
        </div>
        <iframe :src="confirmation.signedUrl" v-if="confirmation.signedUrl" style="height: 80vh;"/>
        <div class="button-container" v-if="confirmation.status != 'paid' && confirmation.status != 'rejected' && confirmation.status != 'canceled'">
            <q-btn label="Rejeitar" @click="denyConfirmation(confirmation.id)" color="red"/>
            <q-btn label="Aprovar" @click="approveConfirmation(confirmation.id)" color="green"/>
        </div>
    </div>
</template>
<script>
import NotifyMixin from '@/mixins/NotifyMixin';
import UpdatesConfirmations from '@/mixins/UpdatesConfirmations';

export default {
    props: ['confirmation'],
    mixins: [NotifyMixin, UpdatesConfirmations],
}
</script>
<style lang="scss" scoped>
iframe {
    width: 100%;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
</style>