<template lang="">
    <div>
        <MercadoPagoStatus v-if="confirmation.transaction_id" :id="confirmation.transaction_id" />
    </div>
</template>
<script>
import MercadoPagoStatus from '../MercadoPagoStatus.vue';
export default {
    'name': 'CardDetails',
    props: ['confirmation'],
    components: { MercadoPagoStatus },
}
</script>
<style lang="">
    
</style>