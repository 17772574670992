<template lang="">
    <div>
                <q-stepper
                v-model="step"
                vertical
                flat
                color="primary"
                animated
                style="text-align: left;"
                >
                <q-step
                    :name="1"
                    title="Como funciona?"
                    :done="step > 1"
                >
                O método de pagamento "Comprovante PIX" permite enviar um comprovante de pagamento para ser analisado pela equipe. É importante lembrar que a validação pode levar tempo e que o comprovante deve atender aos critérios de validação para ser aceito.

                    <q-stepper-navigation>
                        <q-btn @click="sending = true" color="primary" label="IR PARA PAGAMENTO &raquo;" />
                    </q-stepper-navigation>

                    <div style="margin-top: 16px;" v-if="confirmation.status == 'pending'">
                        <small>Para trocar o método de pagamento, cancele a inscrição atual e inicie uma nova.</small><br>
                        <q-btn color="red" @click="$emit('switch-method')">CANCELAR INSCRIÇÃO</q-btn>
                    </div>
                </q-step>

                <q-step
                    :name="2"
                    title="Envio do comprovante"
                    :done="step > 2"
                >
                        Envie o PIX de {{ price }} para a chave <b>{{ registration?.pricing_tiers?.custom_pix_key ?? 'umadc@adcuritiba.org.br' }}</b> e anexe o comprovante da transação abaixo:<br>
                        <input @change="receiptAdded($event)" id="fileInput" type="file"/><br><br>
                        <q-btn @click="submitReceipt" :loading="loading" label="Enviar!" :disabled="! receipt"/>
                </q-step>

                <q-step
                    :name="3"
                    title="Em análise"
                    :done="step > 3"
                >
                    Recebemos o comprovante de pagamento que você enviou através do método "Comprovante PIX". Nossa equipe está analisando o documento e em breve daremos retorno via e-mail!<br>
                    <br>
                    Por favor, esteja ciente de que a validação do comprovante pode levar algum tempo.

                </q-step>

                <q-step
                    :name="4"
                    title="Resultado"
                    :done="step > 4"
                >
                    <div v-if="confirmation.status == 'rejected'">
                        Seu comprovante foi rejeitado pela seguinte razão: <b>{{confirmation.response_data?.reason}}</b><br><br>
                        Inicie uma nova inscrição para enviar novo arquivo.
                    </div>
                    <div v-else>
                        Comprovante <b>aceito</b>!
                    </div>
                </q-step>

            </q-stepper>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import NotifyMixin from '@/mixins/NotifyMixin';
import HumanStringsMixin from '@/mixins/HumanStringsMixin';

export default {
    name: 'FileDetails',
    props: ['confirmation', 'registration'],
    mixins: [NotifyMixin, HumanStringsMixin],
    data() {
        return {
            receipt: null,
            sending: false,
            loading: false,
            submitted: false,
        }
    },
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
        price() {
            let Real = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                });
            return Real.format(this.registration?.frozen_value / 100);
        },
        step() {
            if (this.sending) return 2;
            if (this.submitted) return 3;
            switch(this.confirmation.status) {
                case 'pending':
                    return 1;
                case 'analysis':
                    return 3;
                case 'rejected':
                case 'paid':
                    return 4;
            }
            return 1;
        },
        actualConfirmation() {
            return this.$store.getters['registrations/getSingleRegistration'](this.registration.id)
        }
    },
    methods: {
        ...mapActions('registrations', ['updateSingleRegistration']),
        receiptAdded(event) {
            if (!event) return;
            this.receipt = event.target.files[0];
        },
        async submitReceipt() {
            if (!this.receipt) return;

            const formData = new FormData();
            formData.append('receipt', this.receipt);

            this.loading = true;            
            axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/v1/confirmation/${this.confirmation.id}/receipt`,
                formData,
                { headers: { Authorization: `Bearer ${this.authenticatedUser.token}`, 'Content-Type': 'multipart/form-data' } }
            )
                .then(() => {
                    this.updateSingleRegistration(this.confirmation.registration_id);
                    this.sending = false;
                    this.loading = false;
                    this.submitted = true;
                }).catch(() => {
                    this.loading = false;
                });
        },
    },
}
</script>
<style lang="">
    
</style>