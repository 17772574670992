<template lang="">
    <div class="venues-form">
        <div class="title-1">
            {{ formTitle }}
        </div>
        <q-form @submit="save">
            <q-input :rules="[required]" v-model="venue.name" label="Nome"/>
            <GoogleMapsAutocomplete v-model:address="venue.address" v-model:lat="venue.lat" v-model:lng="venue.lng" id="maps" :required="true"/>
            <GoogleMap :api-key="'AIzaSyB-H6QbUqGIVmJCURFjxHUekG1clUGo8dY'" :mapId="'59e3920765784629'" :center="geo" style="position: relative; left: -60px; width: calc(100% + 90px); padding: 10px 0px; height: 200px;" :zoom="zoom"
                :clickableIcons="false" :disableDefaultUi="true" :draggable="false" :mapTypeControl="false"
            >
                <Marker :options="{position: geo}"/>
            </GoogleMap>
            <div class="action-buttons-container">
                <q-btn class="primary" @click="cancel">Cancelar</q-btn>
                <q-btn class="primary" type="submit" color="primary">Salvar</q-btn>
            </div>
        </q-form>
    </div>
</template>
<script>
import supabaseInit from "@/supabaseInit";
import { GoogleMap, Marker } from "vue3-google-map";
import { mapActions } from 'vuex';
import ValidationMixin from '@/mixins/ValidationMixin';
import * as Yup from 'yup';

const venueSchema = Yup.object().shape({
    name: Yup.string().required(),
    address: Yup.string().required(),
    lat: Yup.number().required(),
    lng: Yup.number().required(),
});

export default {
    components: {
        GoogleMap, Marker
    },
    props: ['model'],
    mounted() {
        if (this.model) {
            Object.assign(this.venue, this.model);
        }
    },
    mixins: [ValidationMixin],
    data() {
        return {
            venue: {
                id: null,
                name: null,
                address: null,
                lat: null,
                lng: null,
            }
        }
    },
    /* eslint-disable */
    methods: {
        ...mapActions(['hideOverlay']),
        ...mapActions('venues', ['pushVenue', 'updateSingleVenue']),
        async save() {
            const data = {
                name: this.venue.name,
                address: this.venue.address,
                lat: this.venue.lat,
                lng: this.venue.lng,
            }

            try {
                venueSchema.validateSync(data);
            } catch (e) {
                console.error(e);
                return;
            }

            if (this.venue.id)
                data.id = this.venue.id;
            
            const newVenue = await supabaseInit.from('venues').upsert(data).select();

            if (! data.id) {
                this.pushVenue(newVenue.data[0]);
                this.$emit('selected', newVenue.data[0]);
            }
            this.updateSingleVenue(newVenue.data[0].id);
            this.hideOverlay();
        },
        cancel() {
            this.hideOverlay();
        }
    },
    computed: {
        formTitle() {
            return this.venue.id == null ? 'Novo local' : 'Editar local';
        },
        geo() {
            return {
                lat: this.venue?.lat ?? -25.4385783,
                lng: this.venue?.lng ?? -49.2802304,
            }
        },
        zoom() {
            return this.venue?.lat != null ? 15 : 5;
        },
    },
    watch: {
        address(newVal) {
            this.venue.lat = newVal?.geometry?.location?.lat();
            this.venue.lng = newVal?.geometry?.location?.lng();

            this.venue.address = newVal.formatted_address;
        }
    }
}
</script>
<style lang="scss" scoped>
    .venues-form {
        padding: 30px;
        text-align: left;
    }

    .title-1 {
        color: #333;
        line-height: 30px;
        font-size: 28px;
        font-weight: 700;
    }

    #maps {
        margin-bottom: 16px;
    }

    .action-buttons-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 16px 0px;
    }
</style>