<template>
  <div style="padding: 30px; text-align: center;">
    <h1>Todos os Eventos</h1>
    <q-btn class="query-button" outline color="grey" @click="queryPreviousMonth()" label="&laquo; carregar eventos antigos"/>
    <card
      v-for="event in allEvents"
      :key="event.id"
      @click="showEventDetails(event)"
      class="card"
      >
      <template v-slot:body>
        <EventTitle
          :starts-at="event.starts_at"
          :title="event.title"
          :tags="event.tags"
          :details="event.details"/>
      </template>
    </card>
    <q-btn class="query-button" outline color="grey" @click="queryNextMonth()" label="carregar próximos eventos &raquo;"/>
  </div>
</template>

<script>
import EventTitle from "@/components/EventTitle.vue"
import Card from '@/components/Card.vue';
import { mapActions } from 'vuex'
import moment from 'moment';

export default {
  name: 'AllEventsView',
  components: { EventTitle, Card },
  methods: {
    ...mapActions(['showOverlay']),
    ...mapActions('events', ['queryEventsForMonth', 'updateSingleEvent']),
    showEventDetails(event) {
      this.updateSingleEvent(event.id);
      this.showOverlay({'component': 'EventBody', props: {'event': event}, events: []});
    },
    queryNextMonth() {
      this.higherMonth++;
      this.queryEventsForMonth(this.higherMonth);
    },
    queryPreviousMonth() {
      this.lowerMonth--;
      this.queryEventsForMonth(this.lowerMonth);  
    }
  },
  data() {
    return {
      selectedTags: [],
      higherMonth: null,
      lowerMonth: null,
    }
  },
  computed: {
    allEvents() { return this.$store.getters['events/getAllEvents'] }
  },
  mounted() {
    this.queryEventsForMonth(moment().month());
    this.higherMonth = moment().month();
    this.lowerMonth = moment().month();
  }
}
</script>

<style lang="scss" scoped>
  h1 {
    color: #333;
    line-height: 30px;
    font-size: 28px;
    font-weight: 700;
    text-align: left;
  }

  h2 {
    font-weight: 300;
    color: #666;
    margin: 0;
    margin: 0px 30px;
    padding: 8px 0px;
  }

  .card {
    margin: 8px 0px;
  }

  #regionais {
    margin: 0px 30px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
</style>
