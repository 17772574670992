import Supabase from '@/supabaseInit';
export default {
    namespaced: true,
    state: () => ({
        allVenues: [],
    }),
    mutations: {
        setAllVenues(state, venues) {
            state.allVenues = venues;
        },
        pushVenue(state, venue) {
            state.allVenues.push(venue);
        },
        updateSingleVenue(state, updatedVenue) {
            state.allVenues[updatedVenue.id] = updatedVenue;
        }
    },
    actions: {
        async queryAllVenues({commit}) {
            const supabaseVenues = await Supabase.from('venues')
                .select()
                .is('deleted_at', null);
            
            const venues = [];
            
            supabaseVenues.data.forEach((venue) => venues[venue.id] = venue);

            commit('setAllVenues', venues);
        },
        pushVenue({commit}, venue) {
            commit('pushVenue', venue);
        },
        async updateSingleVenue({commit}, id) {
            let updatedVenue = await Supabase
                .from('venues')
                .select()
                .is('deleted_at', null)
                .eq('id', id)
                .limit(1)
                .select();

            commit('updateSingleVenue', updatedVenue.data[0]);
        }
    },
    getters: {
        getAllVenues(state) {
            return Object.values(state.allVenues);
        }
    }
}