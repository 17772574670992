<template lang="">
    <div v-if="confirmation">
        <div class="button-container" v-if="confirmation.status == 'paid'">
            Voucher válido aplicado.
        </div>
        <div v-else>
            Voucher inválido aplicado
        </div>
    </div>
</template>
<script>

export default {
    props: ['confirmation']
}
</script>
<style lang="scss" scoped>
    .button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
</style>