<template lang="">
    <div>
        <q-input :label="confirmation.request_data.pixCopiaECola" readonly v-model="qrCC" v-if="confirmation.status != 'paid'">
            <template v-slot:append>
                <q-btn label="Copiar" @click="copyPix()" />
            </template>
        </q-input>
        <img v-if="confirmation.status != 'paid'" :src="qrUrl" />
    </div>
</template>
<script>
import QRCode from 'qrcode'

export default {
    'name': 'PixDetails',
    props: ['confirmation'],
    data() {
        return {
            qrUrl: null,
            qrCc: null,
        }
    },
    methods: {
        async copyPix() {
            await navigator.clipboard.writeText(this.confirmation.request_data.pixCopiaECola);
        }
    },
    async mounted() {
        this.qrUrl = await QRCode.toDataURL(this.confirmation.request_data.pixCopiaECola, { errorCorrectionLevel: 'L' });
    },
}
</script>
<style lang="">
    
</style>