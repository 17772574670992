<template lang="">
    <div class="registrations-container">
      <h1>Inscrições</h1>
      <q-chip :outline="! searchFilter || ! searchFilter.custom || searchFilter.custom != 'FILE_ANALYSIS'" color="primary" text-color="white" size="md"  clickable @click="setFilter('FILE_ANALYSIS')">
        Comprovantes em análise
      </q-chip>
      <q-chip :outline="! searchFilter || ! searchFilter.custom || searchFilter.custom != 'CASH_PENDING'" color="primary" text-color="white" size="md"  clickable @click="setFilter('CASH_PENDING')">
        Pendentes em Pagamento presencial
      </q-chip>
      <q-chip :outline="! searchFilter || ! searchFilter.custom || searchFilter.custom != 'PAID'" color="primary" text-color="white" size="md"  clickable @click="setFilter('PAID')">
        Pagos
      </q-chip>
      <q-chip :outline="! searchFilter || ! searchFilter.custom || searchFilter.custom != 'PIX_EXPIRED'" color="primary" text-color="white" size="md"  clickable @click="setFilter('PIX_EXPIRED')">
        PIX Expirados
      </q-chip>
      <q-chip outline color="primary" text-color="white" size="md" clickable @click="() => setFilter()">
        Ver tudo
      </q-chip>
      <q-chip outline color="primary" text-color="white" size="md" clickable @click="() => showFilterForm = !showFilterForm">
        Outros filtros
      </q-chip>
      <q-form @submit="searchConfirmations" v-if="showFilterForm">
        <q-select :options="statuses"
          v-model="searchFilter.status"
          name="status"
          behavior="menu"
          option-value="value"
          option-label="label"
          label="Status da inscrição"/>
        <q-select :options="paymentMethods"
          v-model="searchFilter.paymentMethod"
          behavior="menu"
          name="paymentMethod"
          option-value="value"
          option-label="label"
          label="Método de pagamento"/>
        <q-select :options="reallyGetAllEvents"
            v-model="searchFilter.eventId"
            name="event"
            behavior="menu"
            option-value="id"
            option-label="title"
            label="Evento"/>
        <q-input v-model="searchFilter.text" name="text" label="Nome ou email"/>
        <q-btn label="Filtrar &raquo;" outline color="primary" type="submit" style="margin: 8px 0px; width: 100%;" />
      </q-form>
      <q-list id="list" bordered>
        <q-item v-if="getAllPendingConfirmations.length == 0">
          Não há inscrições para os filtros selecionados.
        </q-item>
        <q-expansion-item
          expand-separator
          switch-toggle-side
          :label="formatCaptions(confirmation)"
          :caption="confirmation.registrations.user_id"
          v-for="confirmation in getAllPendingConfirmations.sort((a, b) => a?.registrations?.users?.name.localeCompare(b?.registrations?.users?.name))"
          :key="confirmation.id"
          @show="getUrl(confirmation)"
        >
        <template v-slot:header>
          <q-item-section>
            <small>{{ formatCaptions(confirmation) }} | {{ humanPaymentMethod(confirmation.strategy) }} - {{ humanStatus(confirmation.status) }}</small><span style="display: block;">{{ confirmation?.registrations?.users?.name }}<br>{{ confirmation?.registrations?.users?.email }}</span>
          </q-item-section>

          <q-item-section side>
            {{formatValue(confirmation.registrations.frozen_value)}}
          </q-item-section>
        </template>
        <q-btn @click="printLabel(confirmation.registration_id, confirmation.registrations.users.name, confirmation.registrations.users.temples.name)">IMPRIMIR ETIQUETA</q-btn>
        <AdminConfirmationDetail :confirmation="confirmation" />
        </q-expansion-item>
      </q-list>
    </div>
</template>
<script>
import NotifyMixin from '@/mixins/NotifyMixin';
import AdminConfirmationDetail from '@/components/admin/AdminConfirmationDetail.vue';
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex'
import HumanStringsMixin from '@/mixins/HumanStringsMixin';

const filters = {
  'FILE_ANALYSIS': {
    text: null,
    status: 'analysis',
    paymentMethod: 'file',
    eventId: null,
  },
  'CASH_PENDING': {
    text: null,
    status: 'pending',
    paymentMethod: 'cash',
    eventId: null,
  },
  'PAID': {
    text: null,
    status: 'paid',
    paymentMethod: null,
    eventId: null,
  },
  'PIX_EXPIRED': {
    text: null,
    status: 'expired',
    paymentMethod: 'mp_pix',
    eventId: null,
  },
};

export default {
  mixins: [NotifyMixin, HumanStringsMixin],
  name: 'AdminConfirmationsView',
  components: {AdminConfirmationDetail},
  methods: {
    ...mapActions('admconfirmations', ['queryConfirmations']),
    formatCaptions(confirmation) {
      return `${confirmation.registrations.pricing_tiers.events.title} - ${confirmation.registrations.pricing_tiers.name}`;
    },
    formatValue(value) {
      if (value === 0) {
        return 'GRÁTIS';
      }

      const Real = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      });

      return Real.format(value / 100);
    },
    async getUrl(confirmation) {
      if (!confirmation.signedUrl && confirmation.request_data?.file) {
        const { data } = await axios.get(
          `${process.env.VUE_APP_BACKEND_URL}/v1/files`, 
          { 
            params: { 
              type: 'receiptFile', 
              filename: confirmation.request_data.file
            }, 
            headers: { 
              Authorization: `Bearer ${this.authenticatedUser.token}`, 
              'Content-Type': 'application/json' 
            } 
          }
        );
        confirmation.signedUrl = data.signedUrl;
      }
    },
    async searchConfirmations() {
      const searchFilter = {
        status: this.searchFilter?.status?.value,
        text: this.searchFilter?.text,
        paymentMethod: this.searchFilter?.paymentMethod?.value,
        eventId: this.searchFilter?.eventId?.id,
      };
      this.withLoader(()=>this.queryConfirmations(searchFilter));
    },
    setFilter(type) {
      this.searchFilter = {
        text: null,
        status: null,
        paymentMethod: null,
        eventId: null,
      };
      if (type) {
        this.searchFilter = filters[type];
        this.searchFilter.custom = type;
      }
      this.withLoader(()=>this.queryConfirmations(this.searchFilter));
    },
    printLabel(regId, userName, templeName) {
      window.open(`/admin/badge?name=${userName}&temple=${templeName}&code=${regId}`);
    }
  },
  data() {
    return {
      confirmations: [],
      searchFilter: {
        text: null,
        status: null,
        paymentMethod: null,
        eventId: null,
      },
      showFilterForm: false,
    }
  },
  mounted() {
    this.setFilter('FILE_ANALYSIS');
    this.confirmations = this.queryConfirmations(this.searchFilter);
  },
  computed: {
    ...mapGetters('admconfirmations', ['getAllPendingConfirmations']),
    ...mapGetters('events', ['reallyGetAllEvents']),
    ...mapGetters('auth', ['authenticatedUser']),
    statuses() {
      return [
        { label: 'Todos', value: null },  
        { label: 'Em análise', value: 'analysis' },
        { label: 'Pendente', value: 'pending' },
        { label: 'Pago', value: 'paid' },
        { label: 'Rejeitado', value: 'rejected' },
        { label: 'Cancelado', value: 'canceled' },
      ];
    },
    paymentMethods() {
      return [
        { label: 'Todos', value: null },  
        { label: 'Gratuito', value: 'open' },
        { label: 'PIX', value: 'mp_pix' },
        { label: 'Comprovante PIX', value: 'file' },
        { label: 'Dinheiro', value: 'cash' },
        { label: 'PagSeguro', value: 'link' },
        { label: 'Cartão', value: 'mp_card' },
      ]
    },
  }
}
</script>
<style lang="scss" scoped>
.registrations-container {
  padding: 30px;
}

h1 {
  color: #333;
  line-height: 30px;
  font-size: 28px;
  font-weight: 700;
}

h2 {
  font-weight: 300;
  color: #666;
  margin: 0;
  margin: 0px 30px;
  font-size: 24px;
}

#list {
  margin-top: 8px;
}
</style>