import { loadMercadoPago } from "@mercadopago/sdk-js";

export default class {
    mercadoPago;
    inicialized = false;
    bricksBuilder;

    async init() {
        if (this.inicialized) return;
        await loadMercadoPago();
        this.mercadoPago =  new window.MercadoPago(process.env.VUE_APP_MP_PUBLIC_KEY, {
            locale: "pt-BR",
        });
    }

    getBricksBuilder() {
        if (this.bricksBuilder) return this.bricksBuilder;
        this.bricksBuilder = this.mercadoPago.bricks();
        return this.bricksBuilder;
    }
}