import { createStore } from 'vuex'
import events from './events';
import auth from './auth';
import venues from './venues';
import speakers from './speakers';
import tags from './tags';
import registrations from './registrations';
import admconfirmations from './admconfirmations';
import checkins from './checkins';
import admreports from './admreports';
import Supabase from '@/supabaseInit';

export default createStore({
  state: {
    overlay: {
      type: null,
      props: null,
      events: null,
      visible: false,
    },
    menu: {
      visible: false,
    },
    temples: [],
  },
  getters: {
    overlay(state) {
      return state.overlay;
    },
    isOverlayVisible(state) {
      return state.overlay.visible;
    },
    isMenuVisible(state) {
      return state.menu.visible;
    },
    temples(state) {
      return state.temples;
    }
  },
  mutations: {
    setOverlay(state, {component, props, events}) {
      state.overlay.type = component;
      state.overlay.props = props;
      state.overlay.events = events;
    },
    setOverlayVisibility(state, visibility) {
      state.overlay.visible = visibility;
    },
    setMenuVisibility(state, visibility) {
      state.menu.visible = visibility;
    },
    setTemples(state, temples) {
      state.temples = temples;
    }
  },
  actions: {
    showOverlay({commit}, {component, props, events}) {
      commit('setOverlay', {component, props, events});
      commit('setOverlayVisibility', true);
      commit('setMenuVisibility', false);
    },
    hideOverlay({commit}) {
      commit('setOverlayVisibility', false);
      setTimeout(() => commit('setOverlay', {component: null, props: null, events: null}), 400)
    },
    toggleMenu({commit, state}) {
      commit('setMenuVisibility', ! state.menu.visible);
    },
    async queryTemples({commit}) {
      Supabase.from('temples').select('id,name')
      .eq('ours', true)
      .then(
        ({data}) => commit('setTemples', data.sort((a, b) => a.name.localeCompare(b.name)))
      )
    }
  },
  modules: {
    auth,
    events,
    venues,
    speakers,
    tags,
    registrations,
    admconfirmations,
    checkins,
    admreports,
  }
})