export default {
  methods: {
    humanStatus(status) {
      switch (status) {
        case 'paid':
          return 'Pago';
        case 'rejected':
          return 'Recusado';
        case 'pending':
          return 'Pendente';
        case 'analysis':
          return 'Em análise';
        case 'canceled':
          return 'Cancelada';
        default:
          return 'Outro status';
      }
    },
    humanPaymentMethod(paymentMethod) {
      switch (paymentMethod) {
        case 'file':
          return 'PIX';
        case 'cash':
          return 'Pagamento presencial';
        case 'voucher':
          return 'Voucher';
        case 'link':
          return 'PagSeguro';
        default:
          return 'Outro meio';
      }
    }
  }
}