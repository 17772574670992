<template lang="">
    <div>
        <div :id="'statusBrick-' + id"></div>
    </div>
</template>
<script>
import { buildMpStatusConfig } from '@/configs/mpConfig';

export default {
    name: 'MercadoPagoStatus',
    props: ['id'],
    async mounted() {
        await this.mercadoPago.init();
        let bricksBuilder = this.mercadoPago.getBricksBuilder();
        window.statusBrickController = await bricksBuilder.create(
            'statusScreen',
            'statusBrick-'+this.id,
            buildMpStatusConfig(this.id),
        );
    },
    unmounted() {
        window.statusBrickController.unmount();
    }

}
</script>
<style lang="">
    
</style>