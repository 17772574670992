<template lang="">
    <div id="forgot-container">
        <span id="p2">Esqueceu a senha?<br>Não tem problema!</span>
        <span>Confirme seu endereço de email abaixo, para recuperar sua senha:</span>
        <q-form>
            <q-input :rules="[required, emailRule]" v-model="email" />
            <q-btn style="margin-top: 16px;" @click="sendEmail()" :loading="loading" outline>Recuperar senha &raquo;</q-btn>
        </q-form>
    </div>
</template>
<script>
import NotifyMixin from '@/mixins/NotifyMixin';
import supabaseInit from '../../supabaseInit';
import ValidationMixin from '@/mixins/ValidationMixin';
import * as Yup from 'yup';

const pwdResetSchema = Yup.object().shape({
  email: Yup.string().min(6).required(),
});

export default {
    mixins: [NotifyMixin, ValidationMixin],
    data() {
        return {
            email: null,
            loading: false,
        }
    },
    methods: {
        async sendEmail() {
            pwdResetSchema.validateSync({
                email: this.email,
            });
            this.loading = true;
            try {
                await supabaseInit.auth.resetPasswordForEmail(this.email, {redirectTo: process.env.VUE_APP_URL + '/auth/reset-password'});
                this.$q.notify({
                    type: 'positive',
                    html: true,
                    position: 'top',
                    message: `Caso exista uma conta criada para esse email, enviamos um email de redefinição de senha para ${this.email}! Se você não receber o email em alguns minutos, confirme se esse foi o email usado para criar sua conta e verifique também sua caixa de spam.`,
                    timeout: 30000,
                })
                this.$router.push('/auth/login');
            } catch (e) {
                this.$q.notify({
                    type: 'negative',
                    position: 'top',
                    html: true,
                    message: "Não foi possível completar sua solicitação nesse momento. Tente novamente em alguns momentos!",
                })
            }
            this.loading = false;
        }
    },
    mounted() {
        this.email = this.$route.query.email;
    }
}
</script>
<style lang="scss" scoped>
#forgot-container {
    height: 100vh;
    width: 100vw;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 30px;
    gap:16px;
}

#p2 {
    font-size: 24px;
    letter-spacing: -2px;
    font-weight: 700;
    line-height: 26px;
    background: linear-gradient(100deg, rgb(189, 255, 239) 0%, rgb(207, 231, 255) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>