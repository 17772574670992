
import './styles/quasar.scss'
import iconSet from 'quasar/icon-set/material-icons-outlined.js'
import lang from 'quasar/lang/pt-BR.js'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify,
    Loading,
    Dialog
  },
  lang: lang,
  iconSet: iconSet
}