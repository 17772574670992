<template lang="">
    <div>
        <q-stepper
                v-model="step"
                vertical
                flat
                color="primary"
                animated
                style="text-align: left;"
                >
                <q-step
                    :name="1"
                    title="Como funciona?"
                    :done="step > 0"
                >
                    O pagamento será realizado através do PagSeguro. O pagamento pode levar até 72h para ser confirmado.<br>
                    Clique no botão abaixo para abrir a página de pagamento.<br><br>
                    <q-btn :href="registration.pricing_tiers.payment_link" target="_blank" label="IR PARA PAGAMENTO &raquo;" color="primary" />
                    <div style="margin-top: 16px;" v-if="confirmation.status == 'pending'">
                        <small>Para trocar o método de pagamento, cancele a inscrição atual e inicie uma nova.</small><br>
                        <q-btn color="red" @click="$emit('switch-method')">CANCELAR INSCRIÇÃO</q-btn>
                    </div>
                </q-step>
                <q-step
                    :name="2"
                    title="Resultado"
                    :done="step > 1"
                >
                    <div v-if="confirmation.status == 'rejected'">
                        No momento do evento não foi possível pagar: <b>{{confirmation.response_data?.reason}}</b><br><br>
                        Inicie uma nova inscrição para pagar com outro método.
                    </div>
                    <div v-else>
                        Pagamento <b>realizado</b>!
                    </div>
                </q-step>
        </q-stepper>
    </div>
</template>
<script>

export default {
    'name': 'LinkDetails',
    props: ['confirmation', 'registration'],
    computed: {
        step() {
            switch(this.confirmation.status) {
                case 'pending':
                    return 1;
                case 'rejected':
                case 'paid':
                    return 2;
            }
            return 1;
        }
    }
}
</script>
<style lang="">
    
</style>