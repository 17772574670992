import axios from 'axios';
import { mapGetters } from 'vuex';

const UploadMixin = {
    computed: {
        ...mapGetters('auth', ['authenticatedUser']),
    },
    methods: {
        async uploadFile(type, file) {
            const formData = new FormData();
            formData.append('file', file);
            const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/v1/admin/files?type=${type}`, formData, {
                headers: {
                    Authorization: `Bearer ${this.authenticatedUser.token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        },
        async deleteFile(type, filePath) {
            const response = await axios.delete(`${process.env.VUE_APP_BACKEND_URL}/v1/admin/files?type=${type}&filePath=${filePath}`, {
                headers: {
                    Authorization: `Bearer ${this.authenticatedUser.token}`,
                },
            });
            return response.data;
        },
    },
};

export default UploadMixin;