import Supabase from '@/supabaseInit';
export default {
    namespaced: true,
    state: () => ({
        allSpeakers: [],
    }),
    mutations: {
        setAllSpeakers(state, speakers) {
            state.allSpeakers = speakers;
        },
        pushSpeaker(state, speaker) {
            state.allSpeakers.push(speaker);
        },
    },
    actions: {
        async queryAllSpeakers({commit}) {
            Supabase.from('speakers')
                .select()
                .is('deleted_at', null)
                .then(({data}) => commit('setAllSpeakers', data))
                .select();
        },
        pushSpeaker({commit}, speaker) {
            commit('pushSpeaker', speaker);
        },
    },
    getters: {
        getAllSpeakers(state) {
            return state.allSpeakers;
        }
    }
}