import { mapActions, mapGetters } from "vuex";
import CheckinView from '@/views/checkin/CheckinView.vue';

export default {
    computed: {
        ...mapGetters('checkins', ['activeCheckins']),
        ...mapGetters('auth', ['authenticatedUser']),
    },
    methods: {
        ...mapGetters('registrations', ['getEventRegistration']),
        ...mapActions('checkins', ['queryActiveCheckins']),
        ...mapActions('registrations', ['queryRegistrations']),
        ...mapActions('events', ['updateSingleEvent']),
        ...mapActions(['showOverlay', 'hideOverlay']),
        async joinEvent(event) {
            if (event.external_redirect) {
                window.open(event.external_redirect, '_blank');
                return;
            }
            
            await this.updateSingleEvent(event.id);
            if (! this.authenticatedUser) {
                this.showOverlay({'component': 'EventBody', props: {'event': event}, events: []});
                return;
            }

            await this.queryRegistrations();
            const eventRegistration = this.getEventRegistration()(event.id);

            if (eventRegistration == undefined || eventRegistration.status != 'paid') {
                this.showOverlay({'component': 'EventBody', props: {'event': event}, events: []});
                return;
            }

            if (event.checkin_policy == 'requires') {
                await this.queryActiveCheckins();
                if (! this.activeCheckins[event.id]) {
                    this.showOverlay({
                        'component': CheckinView,
                        props: { 'event': event, 'registration': eventRegistration },
                        events: {checkedin: () => this.hideOverlay() && this.$router.push('/events/'+event.id)}
                    });
                    return;
                }
            }

            this.$router.replace('/events/'+event.id);
        },
    }
}