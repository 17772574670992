import Supabase from '@/supabaseInit';

export default {
    namespaced: true,
    state: () => ({
        activeCheckins: [],
    }),
    mutations: {
        pushActiveCheckins(state, checkins) {
            const tempActiveCheckins = state.activeCheckins;
            checkins.forEach(checkin => {
                tempActiveCheckins[checkin.event_id] = checkin;
            });
            state.activeCheckins = tempActiveCheckins;
        }
    },
    actions: {
        async queryActiveCheckins({commit, rootState}) {
            const {data, error} = await Supabase
                                    .from('checkins')
                                    .select('id,event_id')
                                    .eq('user_id', rootState.auth.authenticatedUser.id);
            
            error && console.error(error);

            commit('pushActiveCheckins', data);
        }
    },
    getters: {
        activeCheckins(state) {
            return state.activeCheckins;
        }
    }
}