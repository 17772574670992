<template lang="">
    <card
        @click="showRegistrationDetails()">
        <template v-slot:body>
        <EventTitle
            :starts-at="event.starts_at"
            :title="event.title"
            :tags="[]"
            :extra-padding="true"/>
        </template>
    </card>
</template>
<script>
import { mapActions } from 'vuex';
import RegistrationDetails from '../views/registration/RegistrationDetails.vue';
import EventTitle from "@/components/EventTitle.vue";
import Card from "@/components/Card.vue";

export default {
    name: 'RegistrationListItem',
    props: ['registration'],
    components: { EventTitle, Card },
    methods: {
        ...mapActions(['showOverlay']),
        showRegistrationDetails() {
            this.showOverlay({'component': RegistrationDetails, props: {'registration': this.registration}, events: []});
        }
    },
    computed: {
        event() {
            return this.registration.pricing_tiers.events;
        }
    }
}
</script>
<style lang="">
    
</style>